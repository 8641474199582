import React from 'react';
import styles from '../styles.module.scss';
import VariantTile from "../VariantTile";
import {IInquiryVariant} from "../../../../model/inquiryVariant";
import {changeInquiryVariant, ISelectedInquiryVariant} from "../../../../store/reducers/axaInsuranceSlice";
import {useDispatch} from "react-redux";

interface IMobileTilesHostProps {
    inquiryVariants: IInquiryVariant[];
    selectedInquiryVariant: ISelectedInquiryVariant | null;
}

const MobileTilesHost: React.FC<IMobileTilesHostProps> = (props: IMobileTilesHostProps) => {
    const dispatch = useDispatch();
    const handleVariantChange = (selectedVariantId: string) => {

        const inquiryVariant: ISelectedInquiryVariant = {
            id: selectedVariantId,
            alcoholClause: !!props.selectedInquiryVariant?.alcoholClause,
            physicalWork: !!props.selectedInquiryVariant?.physicalWork,
            isTermsAgreement: props.selectedInquiryVariant?.isTermsAgreement || false,
        }

        dispatch(changeInquiryVariant(inquiryVariant));
    }
    const selectedColumnIndex: number = props.inquiryVariants.findIndex((variant: IInquiryVariant) => variant.id === props.selectedInquiryVariant?.id);

    return (
        <div className={styles.variantTilesContainer}>
            {props.inquiryVariants.map((inquiryVariant: IInquiryVariant, columnIndex: number) => (
                <div key={inquiryVariant.id}
                     className={`${styles.variantTile} ${selectedColumnIndex === columnIndex ? styles.selected : ''}`}
                     onClick={() => handleVariantChange(inquiryVariant.id)}>
                    <VariantTile inquiryVariant={inquiryVariant}/>
                </div>
            )) }
        </div>
    )

};

export default MobileTilesHost;
