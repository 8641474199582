import React from 'react';
import styles from '../../styles.module.scss';

import {
    IVariantStructureCell,
    IVariantStructureCellIcon,
    IVariantStructureCellString,
    IPrice
} from "../../variantsStructureData";
import Price from "../../../shared/Price";

interface IVariantsTableCellProps {
    cellObj: IVariantStructureCell;
}

const VariantsTableCell: React.FC<IVariantsTableCellProps> = (props: IVariantsTableCellProps) => {
    const renderCell = (cellObj: IVariantStructureCell) => {
        switch (cellObj.type) {
            case 'numeric':
                return (<Price priceObj={(cellObj.value as IPrice)} dec={0} class={'small'}/>)
            case 'string':
                return (
                    <p className={`label ${(cellObj.value as IVariantStructureCellString).class}`}
                       dangerouslySetInnerHTML={{ __html: (cellObj.value as IVariantStructureCellString).label }}/>
                )
            case 'icon':
                return (
                    <span className={`${styles.valueIcon} ${(cellObj.value as IVariantStructureCellIcon).icon === 'check'? styles.available : styles.notAvailable}`}/>
                )
            default:
                return (<span>-</span>)
        }
    }

    return (
        <div className="variables-table-cell">
            { renderCell(props.cellObj) }
        </div>
    )
};

export default VariantsTableCell;
