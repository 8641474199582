import React, { FC } from 'react';
import AccordionItem from "./AccordionItem";

interface AccordionProps {
    items: { title: string; content: string[]; titleClassName?: string; contentClassName?: string  }[];
}

const Accordion: FC<AccordionProps> = ({ items }) => {
    return (
        <div>
            {items.map((item, index) => (
                <AccordionItem key={index}
                               title={item.title}
                               content={item.content}
                               titleClassName={item?.titleClassName}
                               contentClassName={item?.contentClassName}/>
            ))}
        </div>
    );
};

export default Accordion;
