import {educatAPI, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';

export interface ICheckoutSessionPayload {
    successUrl: string;
}

export function checkoutSessionAPI(paymentId: string, payload: ICheckoutSessionPayload): Observable<any> {
    return educatAPI.put(
        `payment/${paymentId}/checkout_session`,
        payload,
        new RestQueryParams(),
        {}
    );
}