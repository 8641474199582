import {educatAPI, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';
import {IInquiryInput} from "../../model/inquiry";


export function updateInquiryAPI(inquiryId: string, secret: string, payload: IInquiryInput): Observable<any> {
    const headers = {
        'X-Inquiry-Secret': secret
    };

    return educatAPI.put(
        `inquiry/${inquiryId}/update`,
        payload,
        new RestQueryParams(),
        headers
    );
}