const pl = {
    app: {
        title: 'Educat Axa',
        companyTitle: 'Educat Axa',
    },
    inquiry: {
        title: 'EduCat Insurance',
        wizardSteps: {
            data: 'Zakres ubiezpieczenia',
            subject: 'Dane studenta',
            variant: 'Wariant',
            summary: 'Podsumowanie',
            payment: 'Płatność'
        },
        inquiryData: {
            description: 'Uzupełnij dane dotyczące zakresu ubezpieczenia',
            formControls: {
                labels: {
                    insuranceDate: 'Data rozpoczęcia okresu ubezpieczenia',
                    insuranceDuration: 'Długość okresu ubezpieczenia',
                    customDuration: 'Inna długość ubezpieczenia',
                    month: 'miesięcy',
                    residencyCountry: 'Kraj zamieszkania',
                    studyCountry: 'Kraj studiowania',
                    schoolName: 'Nazwa uczelni',
                    tripReason: 'Typ studiów/programu'
                },
                placeholders: {
                    insuranceDate: 'Wybierz datę',
                    residencyCountry: 'Wybierz z listy',
                    studyCountry: 'Wybierz z listy',
                    schoolName: 'Wpisz nazwę',
                },
                tripReasons: {
                    bachelor_degree_studies: 'Licencjackie',
                    engineering_studies: 'Inżynierskie',
                    master_degree_studies: 'Magisterskie',
                    doctoral_studies: 'Doktoranckie',
                    mba_studies: 'MBA',
                    high_school_abroad: 'Liceum za granicą',
                    erasmus_or_exchange: 'Erasmus lub wymiana',
                    other: 'Inne',
                },
                insuranceDuration: {
                    '1': '1 miesiąc',
                    '2': '2 miesiące',
                    '3': '3 miesiące',
                    '4': '4 miesiące',
                    '5': '5 miesięcy',
                    '6': '6 miesięcy',
                },
            },
        },
        inquirySubject: {
            description: 'Uzupełnij dane dotyczące osoby ubezpieczanej',
            formControls: {
                labels: {
                    firstName: 'Imię',
                    lastName: 'Nazwisko',
                    birthDate: 'Data urodzenia',
                    address: 'Adres',
                    postCode: 'Kod pocztowy',
                    city: 'Miasto',
                    email: 'Email',
                    phone: 'Numer telefonu',
                },
                placeholders: {
                    firstName: 'Wpisz tutaj',
                    lastName: 'Wpisz tutaj',
                    birthDate: 'Wybierz datę',
                    address: 'Wpisz tutaj',
                    postCode: '__-___',
                    city: 'Wpisz tutaj',
                    email: 'Wpisz tutaj',
                    phone: '___ ___ ___',
                },
            },
        },
        inquiryVariant: {
            description: 'Wybierz ofertę ubezpieczenia dla siebie',
            coverageDuration: "za osobę za 30 dni",
            details: {
                treatmentCosts: 'Koszty leczenia i assistance (KL)',
                dentistCosts: 'Limit na leczenie stomatologiczne',
                clinicTransportation: 'Transport do/między z placówki medycznej',
                emergencyMedicalServices: 'Ratownictwo',
                residencyCountryTransportation: 'Transport do Kraju stałego zamieszkania',
                residencyTransportation: 'Transport do Miejsca zamieszkania',
                extendedStay: 'Przedłużony pobyt',
                baggageDelay: 'Opóźnienie Bagażu',
                coffinTransportation: 'Transport zwłok oraz zakup trumny',
                assistantCosts: 'Pobyt osoby wezwanej do Ubezpieczonego',
                assistanceCenter: 'Dyżur Centrum Pomocy Assistance',
                flightDelay: 'Opóźnienie lub odwołanie lotu',
                accidents: 'Następstwa Nieszczęśliwych wypadków (NNW)',
                permanentHealthDamage: 'Trwały uszczerbek na zdrowiu',
                death: 'Śmierć',
                luggage: 'Bagaż',
                specialLuggage: 'Elektronika, Sprzęt sportowy',
                liability: 'Odpowiedzialność cywilna (OC)',
                personalInjuries: 'Szkody osobowe',
                propertyDamage: 'Szkody rzeczowe',
                damageShare: 'Udział własny przyszkodach poniżej 150 EUR',
                studyInterruption: 'Przerwanie nauki',
                semesterPaymentRefund: 'Zwrot opłaty za semestr nauki',
                inquiryExpansion: 'Rozszerzenia ochrony',
                alcoholClause: 'Klauzula alkoholowa',
                physicalClause: 'Praca fizyczna',
                europeAdditionalPrice: 'Składka za osobę za 30 dni EUROPA',
                worldAdditionalPrice: 'Składka za osobę za 30 dni CAŁY ŚWIAT'
            },
            tooltips: {
                treatmentCosts: 'Jeżeli podczas pobytu za granicą doznasz urazu lub zachorujesz, pokryjemy koszty pomocy medycznej, leczenia, leków i niezbędnego transportu (interwencja górskiego pogotowia ratunkowego czy transport śmigłowcem). Postaramy się, abyś z zagranicznej podróży bezpiecznie wrócił do domu.',
                accidents: 'Ubezpieczenie następstw nieszczęśliwych wypadków obejmuje świadczenia na wypadek trwałego uszczerbku na zdrowiu lub śmierci w wyniku wypadku.',
                luggage: 'Dotyczy przedmiotów osobistych, które zabrałeś ze sobą w podróż zagraniczną lub kupiłeś w jej trakcie. Przysługują Ci świadczenia w przypadku ich utraty lub zniszczenia w wyniku kradzieży lub rabunku.',
                liability: 'Dotyczy szkód osobowych lub majątkowych wyrządzonych osobie trzeciej przy wykonywaniu zwykłych czynności (sport, rekreacja, rozrywka).',
                studyInterruption: 'Przerwanie nauki - Ubezpieczyciel zwraca koszty poniesione przez Ubezpieczonego w związku z przerwaniem nauki:\n \n 1) Nagłe zachorowanie lub Zachorowanie na COVID-19 Ubezpieczonego lub Współubezpieczonego, pod warunkiem, iż zgodnie z rokowaniami lekarza prowadzącego nie może kontynuować nauki; \n\n2) Nieszczęśliwy wypadek Ubezpieczonego lub Współubezpieczonego, pod warunkiem, iż zgodnie z rokowaniami lekarza prowadzącego nie jest prawdopodobny powrót do zdrowia w krótkim czasie;\n\n 3) zgon Ubezpieczonego;\n\n 4) Nagłe zachorowanie Osoby bliskiej Ubezpieczonego w związku z którym zgodnie jego obecność jest konieczna w celu sprawowania opieki nad tą osobą. W powyższej sytuacji konieczność pozostania z Osobą bliską, która uległa Nagłemu zachorowaniu musi zostać potwierdzona pisemnym zaświadczeniem lekarskim;\n\n 5) Nieszczęśliwy wypadek Osoby bliskiej Ubezpieczonego w związku z którym jego obecność jest konieczna w celu sprawowania opieki nad tą osobą. W powyższej sytuacji konieczność pozostania z Osobą bliską, która uległa Nieszczęśliwemu wypadkowi musi zostać potwierdzona pisemnym zaświadczeniem lekarskim;\n\n 6) rozpoczęcie pracy przez Ubezpieczonego, wyznaczone przez pracodawcę na dzień przypadający podczas trwania nauki.',
                inquiryExpansion: 'Rozwiń, by wyświetlić rozszerzenia ochrony dla spożywania alkoholu i pracy fizycznej',
                alcoholClause: 'Ubezpieczenie odpowiednie dla osób, które chcą być ubezpieczone także w przypadku spożywania alkoholu.',
                workClause: 'Ubezpieczenie odpowiednie planujących pracę fizyczną za granicą. Obejmuje zdarzenia, do których doszło w związku z wykonywaniem pracy fizycznej.',
            },
            additionalClauses: {
                title: 'Rozszerzenia ochrony',
                alcoholClause: 'Klauzula alkoholowa',
                workClause: 'Praca fizyczna',
                addClause: 'Dodaj'
            },
            checkboxLabel: {
                acceptTerms: 'Akceptuję warunki ',
                privacyPolicy: 'Polityki Prywatności',
            },
            priceLabel: `<0>{ price }</0> { currency }`,
            additionalClausesLabel: `+ <0>{ percentage }</0> %`,
            additionalClauseSummary: `+ <0>{ price }</0> { currency }`
        },
        inquirySummary: {
            description: 'Podsumowanie i płatność',
            labels: {
                firstName: 'Imię',
                lastName: 'Nazwisko',
                birthDate: 'Data urodzenia',
                address: 'Adres',
                postCode: 'Kod pocztowy',
                city: 'Miasto',
                email: 'Email',
                phone: 'Numer telefonu',
                residencyCountry: 'Kraj zamieszkania',
                studyCountry: 'Kraj studiowania',
                insuranceDate: 'Data startu',
                insuranceDuration: 'Liczba miesięcy',
                month: "{count, plural, one {1 miesiąc} few {{count} miesiące} many {{count} miesięcy} other {{count} miesięcy}}"
            },
            consents: {
                title: 'Prosimy o zaakceptowanie zgód',
                all: 'Zaznacz wszystkie:',
                statement: {
                    privacyPolicy: 'Zapoznałem się z Polityką Prywatności.',
                    insuranceDocs: 'Przed zawarciem umowy ubezpieczenia otrzymałem Szczególne Warunki Ubezpieczenia EduCat, Broszurę informacyjną – przetwarzanie danych osobowych i dokument zawierający informacje o produkcie ubezpieczeniowym oraz zobowiązuję się do zapoznania wszystkich ubezpieczonych z treścią tych dokumentów.',
                    requirementsAndNeeds: 'Niniejsza umowa ubezpieczenia jest zgodna z moimi wymaganiami i potrzebami ubezpieczeniowymi.',
                    termsAndConditions: 'Przed zawarciem umowy ubezpieczenia zapoznałem się z Regulaminem zawierania umów na odległość.',
                    emailMarketing: 'Wyrażam zgodę na wykorzystanie podanego przez mnie adresu e-mail do celów marketingu bezpośredniego produktów i usług EduCat.',
                    phoneMarketing: 'Wyrażam zgodę na wykorzystanie podanego przez mnie numer telefonu do celów marketingu bezpośredniego produktów i usług EduCat.',
                }
            }
        },
        purchaseSummary: {
            planDetails: `Pakiet { planName } <0>{ additionalClause }</0>`,
            total: 'Razem do zapłaty',
            monthly: 'miesięcznie',
            alcoholClause: 'klauzula alkoholowa',
            workClause: 'praca fizyczna',
        },
    },
    successPage: {
        adminPanel: {
            title: 'Dziękujemy za zakup!',
            description: 'Na adres email podany w formularzu wysłaliśmy wypełniony wniosek.',
        },
        paid: {
            title: 'Dziękujemy za zakup!',
            description: 'Cieszymy się, że wybrałeś Educat. Na adres email podany w formularzu wysłaliśmy Twoją polisę. W międzyczasie zachęcamy do odwiedzenia naszych profili w social mediach:',
            btnAction: 'Powrót do strony głównej',
        },
        notPaid: {
            title: 'Dziękujemy za zakup!',
            description: 'Przetwarzamy Twoją polisę. Odśwież aby zobaczyć status.',
            btnAction: 'Odśwież',
        }
    },
    buttons: {
        next: 'Przejdź dalej',
        previous: 'Powrót',
        pay: 'Zamawiam i płacę',
        backToMainPage: 'Powrót do strony główej',
        select: 'Wybieram',
        selected: 'Wybrano',
        sendInquiryEmail: 'Wyślij draft polisy na podany email',
    },
    dateRangePicker: {
        config: {
            previousMonth: 'Zeszły miesiąc',
            lastWeek: 'Zeszły tydzień',
            today: 'Dziś',
            currentMonth: 'Ten miesiąc',
        },
    },
    formValidation: {
        errors: {
            minLength: 'Wartość jest zbyt krótka',
            maxLength: 'Wartość jest zbyt długa',
            isEmailValid: 'Email jest nieprawidłowy',
            isRequired: 'Pole jest wymagane',
            isCheckedTrue: 'Pole powinno być zaznaczone',
            isTrue: 'Pole powinno być zaznaczone',
            isPasswordSame: 'Hasła nie mogą być różne',
            isStartDateValid: 'Proszę wybrać datę z przyszłości',
            isStartDateGreaterOrEvenValid: 'Proszę wybrać datę z przyszłości',
            isNumber: 'Podana wartość powinna być liczbą',
            isPasswordString: 'Hasło jest nieprawidłowe.',
            atLeastOneChecked: 'Proszę zaznaczyć przynajmniej jedną opcję',
        },
    },
    footer: {
        srTitle: 'Stopka strony zawierająca dane kontaktowe, nawigację po stronie i zapisy do newslettera',
        addressColumn: {
            title: 'EduCat Services',
            address1: 'ul. Skromna 5/5',
            address2: '20-704 Lublin',
            country: 'Polska',
            nip: 'NIP 7123453240',
            regon: 'REGON 385301298'
        },
        contactColumn: {
            title: 'Dane kontaktowe',
            phone: '+48 531  60 60 03',
            email: 'info@educat.study',
            correspondenceAddressTitle: 'Adres korespondencyjny:',
            address1: 'Al. KEN 93 (iDID)',
            address2: '02-777 Warszawa',
        },
        linksColumn: {
            title: 'Przydatne linki',
            policies: 'Regulamin i polityka prywatności',
        },
        educat: 'Educat { year }',
        copyright: '© Wszelkie prawa zastrzeżone',
        copyrightMobile: 'Educat { year } © Wszelkie prawa zastrzeżone',
        socialIcons: {
            facebook: 'Link prowadzący do profilu na Facebooku',
            instagram: 'Link prowadzący do profilu na Instagramie',
            linkedin: 'Link prowadzący do profilu na Linkedin',
            youtube: 'Link prowadzący do kanalu Youtube',
        }
    },
    maintenance: {
        pageNotFound: '404 - Strona nie została odnaleziona',
    },
    alerts: {
        warning: 'Uwaga!',
        error: 'Błąd!',
        success: 'Sukces!',
        info: 'Informacja',
        baseError: 'Wystąpił błąd, skontaktuj się z administratorem.',
        notFound: 'Oferta polisy o tym id nie istnieje. Skontaktuj się z administratorem',
        tryAgain: 'Spróbuj ponownie',
        unknown: 'Wystąpił nieznany błąd.',
        accessDenied: 'Odmowa dostępu',
    },
    countries: {
        ad: 'Andora',
        ae: 'Zjednoczone Emiraty Arabskie',
        af: 'Afganistan',
        ag: 'Antigua i Barbuda',
        ai: 'Anguilla',
        al: 'Albania',
        am: 'Armenia',
        an: 'Antyle Holenderskie',
        ao: 'Angola',
        ar: 'Argentyna',
        as: 'Samoa Amerykańska',
        at: 'Austria',
        au: 'Australia',
        aw: 'Aruba',
        ax: 'Wyspy Alandzkie',
        az: 'Azerbejdżan',
        ba: 'Bośnia i Hercegowina',
        bb: 'Barbados',
        bd: 'Bangladesz',
        be: 'Belgia',
        bf: 'Burkina Faso',
        bg: 'Bułgaria',
        bh: 'Bahrajn',
        bi: 'Burundi',
        bj: 'Benin',
        bm: 'Bermudy',
        bn: 'Brunei',
        bo: 'Boliwia',
        br: 'Brazylia',
        bs: 'Bahamy',
        bt: 'Bhutan',
        bv: 'Wyspa Bouveta',
        bw: 'Botswana',
        by: 'Białoruś',
        bz: 'Belize',
        ca: 'Kanada',
        cc: 'Wyspy Kokosowe',
        cd: 'Demokratyczna Republika Konga',
        cf: 'Republika Środkowoafrykańska',
        cg: 'Demokratyczne Republika Konga',
        ch: 'Szwajcaria',
        ci: 'Wybrzeże Kości Słoniowej',
        ck: 'Wyspy Cooka',
        cl: 'Chile',
        cm: 'Kamerun',
        cn: 'Chiny',
        co: 'Kolumbia',
        cr: 'Kostaryka',
        ct: 'Canton i Enderbury',
        cu: 'Kuba',
        cv: 'Wyspy Zielonego Przylądka',
        cx: 'Wyspa Bożego Narodzenia',
        cy: 'Cypr',
        cz: 'Czechy',
        de: 'Niemcy',
        dj: 'Dżibuti',
        dk: 'Dania',
        dm: 'Dominika',
        do: 'Dominikana',
        dz: 'Algieria',
        ec: 'Ekwador',
        ee: 'Estonia',
        eg: 'Egipt',
        eh: 'Ghana',
        er: 'Erytrea',
        es: 'Hiszpania',
        et: 'Etiopia',
        fi: 'Finlandia',
        fj: 'Fidżi',
        fk: 'Falklandy (Malwiny)',
        fm: 'Mikronezja',
        fo: 'Wysypy Owcze',
        fr: 'Francja',
        ga: 'Gabon',
        gb: 'Wielka Brytania',
        gd: 'Grenada',
        ge: 'Gruzja',
        gf: 'Gujana Francuska',
        gg: 'Guernsey',
        gh: 'Ghana',
        gi: 'Gibraltar',
        gl: 'Grenlandia',
        gm: 'Gambia',
        gn: 'Gwinea',
        gp: 'Gwadelupa',
        gq: 'Gwinea Równikowa',
        gr: 'Grecja',
        gs: 'Georgia Południowa i Sandwich Południowy',
        gt: 'Gwatemala',
        gu: 'Guam',
        gw: 'Gwinea Bissau',
        gy: 'Gujana',
        hk: 'Hongkong',
        hm: 'Wyspy Heard i McDonalda',
        hn: 'Honduras',
        hr: 'Chorwacja',
        ht: 'Haiti',
        hu: 'Węgry',
        id: 'Indonezja',
        ie: 'Irlandia',
        il: 'Izrael',
        im: 'Wyspa Man',
        in: 'Indie',
        iq: 'Irak',
        ir: 'Iran',
        is: 'Islandia',
        it: 'Włochy',
        io: 'Brytyjskie Terytorium Oceanu Indyjskiego',
        je: 'Jersey',
        jm: 'Jamajka',
        jo: 'Jordan',
        jp: 'Japonia',
        jt: 'Johnston',
        ke: 'Kenia',
        kg: 'Kirgistan',
        kh: 'Kambodża',
        ki: 'Kiribati',
        km: 'Komory',
        kn: 'Saint Kitts i Nevis',
        kp: 'Korea Północna',
        kr: 'Korea Południowa',
        kw: 'Kuwejt',
        ky: 'Kajmany',
        kz: 'Kazachstan',
        la: 'Laos',
        lb: 'Liban',
        lc: 'Saint Lucia',
        li: 'Liechtenstein',
        lk: 'Sri Lanka',
        lr: 'Liberia',
        ls: 'Lesotho',
        lt: 'Litwa',
        lu: 'Luksemburg',
        lv: 'Łotwa',
        ly: 'Libia',
        ma: 'Maroko',
        mc: 'Monako',
        md: 'Mołdawia',
        me: 'Czarnogóra',
        mg: 'Madagaska',
        mh: 'Wyspy Marshalla',
        mi: 'Midway',
        mk: 'Macedonia Północna',
        ml: 'Mali',
        mm: 'Mjanma (Birma)',
        mn: 'Mongolia',
        mp: 'Mariany Północne',
        mq: 'Martynika',
        mr: 'Mauretania',
        ms: 'Montserrat',
        mt: 'Malta',
        mu: 'Mauritius',
        mv: 'Malediwy',
        mw: 'Malawi',
        mx: 'Meksyk',
        my: 'Malezja',
        mz: 'Mozambik',
        na: 'Namibia',
        nc: 'Nowa Kaledonia',
        ne: 'Niger',
        nf: 'Norfolk',
        ng: 'Nigeria',
        ni: 'Nikaragua',
        nl: 'Holandia',
        no: 'Norwegia',
        np: 'Nepal',
        nq: 'Ziemia Królowej Maud',
        nr: 'Nauru',
        nu: 'Niue',
        nz: 'Nowa Zelandia',
        om: 'Oman',
        pa: 'Panama',
        pe: 'Paru',
        pf: 'Polinezja Francuska',
        pg: 'Papua-Nowa Gwinea',
        ph: 'Filipiny',
        pk: 'Pakistan',
        pl: 'Polska',
        pn: 'Pitcairn',
        pr: 'Portoryko',
        ps: 'Palestyna',
        pt: 'Portugalia',
        pu: 'Wyspy USA na Oceanie Spokojnym',
        pw: 'Palau',
        py: 'Paragwaj',
        qa: 'Katar',
        re: 'Reunion',
        ro: 'Rumunia',
        rs: 'Serbia',
        ru: 'Rosja',
        rw: 'Rwanda',
        sa: 'Arabia Saudyjska',
        sb: 'Wyspy Salomona',
        sc: 'Seszele',
        sd: 'Sudan',
        se: 'Szwecja',
        sg: 'Singapur',
        sh: 'Wyspa Świętej Heleny',
        si: 'Słowenia',
        sj: 'Svalbard i Jan Mayen',
        sk: 'Słowacja',
        sl: 'Sierra Leone',
        sm: 'San Marino',
        sn: 'Senegal',
        so: 'Somalia',
        sr: 'Surinam',
        ss: 'Sudan Południowy',
        st: 'Wyspy Świętego Tomasza i Książęca',
        sv: 'Salwador',
        sy: 'Syria',
        sz: 'Eswatini',
        tc: 'Turks i Caicos',
        td: 'Czad',
        tg: 'Togo',
        th: 'Tajlandia',
        tj: 'Tadżykistan',
        tk: 'Tokelau',
        tl: 'Timor Wschodni',
        tm: 'Turkmenistan',
        tn: 'Tunezja',
        to: 'Tonga',
        tp: 'Timor Wschodni',
        tr: 'Turcja',
        tt: 'Trynidad i Tobago',
        tv: 'Tuvalu',
        tw: 'Tajwan',
        tz: 'Tanzania',
        ua: 'Ukraina',
        ug: 'Uganda',
        um: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
        us: 'Stany Zjednoczone',
        uy: 'Urugwaj',
        uz: 'Uzbekistan',
        va: 'Stolica Apostolska',
        vc: 'Saint Vincent i Grenadyny',
        ve: 'Wenezuela',
        vg: 'Brytyjskie Wyspy Dziewicze',
        vi: 'Wyspy Dziewicze Stanów Zjednoczonych',
        vn: 'Wietnam',
        vu: 'Vanuatu',
        wf: 'Wallis i Futuna ',
        wk: 'Wake',
        ws: 'Samoa',
        xa: 'Palestyna',
        xf: 'Wyspy Alandzkie',
        xk: 'Kosowo',
        yd: 'Demokratyczna Republika Jemenu',
        ye: 'Jemen',
        yt: 'Majotta',
        za: 'Afryka Południowa',
        zm: 'Zambia',
        zr: 'Zair',
        zw: 'Zimbabwe',
    },
};

export default pl;
