import {activeLanguageSelector, addAlert, handleApiError} from 'educat-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {RootState} from '../reducers';
import {
    addInquiry,
    changeInquiry,
    changeInquiryData,
    changeInquirySubject,
    changeInquiryVariant,
    changeIsLoading,
    checkInquiryUpdateStatus,
    generatePaymentLink,
    getInquiry,
    IGetInquiry,
    IInquiryConsents,
    IConsentInput,
    IInquiryData,
    IInquirySubject,
    ISelectedInquiryVariant,
    updateInquiry,
    generateInquiryEmail
} from "../reducers/axaInsuranceSlice";
import {IInquiryInput, IInquiryOutput, TripReason, IInquirySubjectOutput} from "../../model/inquiry";
import {
    adminAuthTokenSelector,
    consentsSelector,
    inquiryDataSelector,
    inquirySelector,
    inquirySubjectSelector,
    inquiryVariantSelector,
} from "../selectors/axaInsuranceSelectors";
import moment from 'moment';
import {createInquiryAPI} from "../../api/inquiry/createInquiryAPI";
import {setAcceptedOfferAPI} from "../../api/inquiry/setAcceptedOfferAPI";
import {checkoutSessionAPI, ICheckoutSessionPayload} from "../../api/inquiry/checkoutSessionAPI";
import {updateInquiryAPI} from "../../api/inquiry/updateInquiryAPI";
import {getInquiryAPI} from "../../api/inquiry/getInquiryAPI";
import {PayloadAction} from "@reduxjs/toolkit";
import {ApiSingleResponseBase} from "../../model/base";
import {sendInquiryPropositionAPI} from "../../api/inquiry/sendInquiryPropositionAPI";

export interface ISetAcceptedOfferPayload {
    acceptedOfferId: string;
    consents: IConsentInput[];
}

const getInquiryEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(getInquiry.type),
        switchMap((action: PayloadAction<IGetInquiry>): any => {
            const inquiryId = action.payload.inquiryId,
                secret = action.payload.secret;

            return getInquiryAPI(inquiryId, secret).pipe(
                switchMap((resp: ApiSingleResponseBase<IInquiryOutput>) => {
                    const formattedData: IInquiryOutput = removeSpecialKeys(resp) as IInquiryOutput,
                        inquirySubjectOutput: IInquirySubjectOutput = formattedData.inquirySubjects[0];

                    const inquiryData: IInquiryData = {
                            from: formattedData.from,
                            to: formattedData.to,
                            residencyCountry: inquirySubjectOutput.address.country,
                            studyCountry: formattedData.destination,
                            school: formattedData.schoolName,
                            tripReason: formattedData.tripReason,
                        },
                        inquirySubject: IInquirySubject = {
                            firstName: inquirySubjectOutput.firstName,
                            lastName: inquirySubjectOutput.lastName,
                            birthDate: moment(inquirySubjectOutput.birthDate).format('YYYY-MM-DD'),
                            address: inquirySubjectOutput.address.addressLine1,
                            postCode: inquirySubjectOutput.address.postcode,
                            city: inquirySubjectOutput.address.city,
                            email: inquirySubjectOutput.email,
                            phone: inquirySubjectOutput.phone,
                        },
                        inquiryVariant: ISelectedInquiryVariant = {
                            id: formattedData.inquiryVariant.id,
                            alcoholClause: !!formattedData?.alcoholClause,
                            physicalWork: !!formattedData?.physicalWork,
                            isTermsAgreement: true,
                        }

                    const actions = [
                        changeInquiryData(inquiryData),
                        changeInquirySubject(inquirySubject),
                        changeInquiryVariant(inquiryVariant),
                        changeInquiry(formattedData as IInquiryOutput),
                        changeIsLoading(false)
                    ];

                    return of(...actions);
                }),
                catchError((error: any) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};
const addInquiryEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(addInquiry.type),
        switchMap((): any => {
            const inquiryData: IInquiryData | null = inquiryDataSelector(state$.value),
                inquirySubject: IInquirySubject | null = inquirySubjectSelector(state$.value),
                selectedInquiryVariant: ISelectedInquiryVariant | null = inquiryVariantSelector(state$.value);

            if (!inquiryData || !inquirySubject || !selectedInquiryVariant) return of();

            const payload: IInquiryInput = getInquiryPayload(inquiryData, inquirySubject, selectedInquiryVariant);

            return createInquiryAPI(payload).pipe(
                switchMap((resp: any) => {

                    const formattedData = removeSpecialKeys(resp);

                    const actions = [
                        changeIsLoading(false),
                        changeInquiry(formattedData as IInquiryOutput)
                    ];
                    return of(...actions);
                }),
                catchError((error: any) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};


const updateInquiryEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(updateInquiry.type),
        switchMap((): any => {
            const inquiry: IInquiryOutput | null = inquirySelector(state$.value),
                selectedInquiryVariant: ISelectedInquiryVariant | null = inquiryVariantSelector(state$.value),
                inquiryData: IInquiryData | null = inquiryDataSelector(state$.value),
                inquirySubject: IInquirySubject | null = inquirySubjectSelector(state$.value);

            if (!inquiry || !selectedInquiryVariant || !inquiryData ||
                !inquiryData.from || !inquiryData.to || !inquirySubject) {

                return of();
            }

            const payload: IInquiryInput = getInquiryPayload(inquiryData, inquirySubject, selectedInquiryVariant);

            return updateInquiryAPI(inquiry.id, inquiry.secret, payload).pipe(
                switchMap((resp: any) => {

                    const formattedData = removeSpecialKeys(resp);

                    const actions = [
                        changeIsLoading(false),
                        changeInquiry(formattedData as IInquiryOutput)
                    ];
                    return of(...actions);
                }),
                catchError((error: any) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const checkInquiryUpdateStatusEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(checkInquiryUpdateStatus.type),
        switchMap((): any => {
            const inquiry = inquirySelector(state$.value);

            if (!inquiry) {
                return of();
            }

            return getInquiryAPI(inquiry.id, inquiry.secret).pipe(
                switchMap((resp: any) => {
                    const actions: any[] = [];

                    const formattedData = removeSpecialKeys(resp);
                    actions.push(changeInquiry(formattedData as IInquiryOutput));
                    actions.push(changeIsLoading(false));
                    return of(...actions);
                }),
                catchError((error: any) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const generatePaymentLinkEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(generatePaymentLink.type),
        switchMap((): any => {
            const inquiryData: IInquiryOutput | null = inquirySelector(state$.value);
            const consents: IInquiryConsents | null = consentsSelector(state$.value);

            if (!inquiryData || !consents) {
                return of();
            }
            const inquiryId = inquiryData.id;
            const acceptOfferPayload: ISetAcceptedOfferPayload = {
                acceptedOfferId: inquiryData.offers[0].id,
                consents: getConsents(consents),
            }

            return setAcceptedOfferAPI(inquiryId, acceptOfferPayload).pipe(
                switchMap((resp: any) => {

                    const lng = activeLanguageSelector(state$.value);
                    const checkoutSessionPayload: ICheckoutSessionPayload = {
                        successUrl: `${process.env.REACT_APP_URL}/${lng}/success`
                    }


                    return checkoutSessionAPI(resp.acceptedOffer.payments[0].id, checkoutSessionPayload).pipe(
                        switchMap((resp: any) => {
                            window.open(resp.url, "_self");

                            return of(changeIsLoading(false));
                        })
                    )
                }),
                catchError((error: any) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const generateInquiryEmailEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(generateInquiryEmail.type),
        switchMap((): any => {
            const inquiryData: IInquiryOutput | null = inquirySelector(state$.value),
                authToken = adminAuthTokenSelector(state$.value);

            if (!inquiryData) {
                return of();
            }

            const inquiryId = inquiryData.id;

            const baseUrl =  `${process.env.REACT_APP_URL}/pl/inquiry/data`,
                params = new URLSearchParams();

            params.append('insuranceId', inquiryId);
            const emailReturnUrl = `${baseUrl}?${params.toString()}`;

            return sendInquiryPropositionAPI(authToken, inquiryId, emailReturnUrl).pipe(
                switchMap((resp: any) => {
                    const lng = activeLanguageSelector(state$.value);
                    const baseUrl = `${process.env.REACT_APP_URL}/${lng}/success`,
                        params = new URLSearchParams();

                    params.append('isAdmin', 'true');
                    const successUrl = `${baseUrl}?${params.toString()}`;
                    window.open(successUrl, "_self");
                    return of(changeIsLoading(false));
                }),
                catchError((error: any) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const errorActions = (error: {[key: string]: any} | string) => {
    const errorObj = handleApiError(error);

    // Quick fix
    if (errorObj.message === 'Not Found') errorObj.message = 'alerts.notFound';

    return [addAlert(errorObj), changeIsLoading(false)];
};

const removeSpecialKeys = (obj: {[key: string]: any}) => {
    if (Array.isArray(obj)) {
        obj.forEach((item, index, array) => {
            array[index] = removeSpecialKeys(item);
        });
    } else if (typeof obj === 'object' && obj !== null) {
        Object.keys(obj).forEach(key => {
            if (['@id', '@type', '@context'].includes(key)) {
                delete obj[key];
            } else {
                obj[key] = removeSpecialKeys(obj[key]);
            }
        });
    }

    return obj;
}


const getTImeZoneStr = (): string => {
    const timezoneNo = Math.round(moment(new Date()).utcOffset() / 60);

    let timezoneStr: string = '';
    if (timezoneNo > 0 ) {
        timezoneStr = timezoneNo < 10 ? `+0${timezoneNo}:00` : `+${timezoneNo}:00`;
    } else {
        timezoneStr = timezoneNo > -10 ? `-0${timezoneNo * -1}:00` : `-${timezoneNo * -1}:00`;
    }

    return timezoneStr;
}

const getInquiryPayload = (
    inquiryData: IInquiryData,
    inquirySubject: IInquirySubject,
    selectedInquiryVariant: ISelectedInquiryVariant): IInquiryInput => {
    return {
        inquiryVariantId: selectedInquiryVariant.id,
        alcoholClause: selectedInquiryVariant.alcoholClause,
        physicalWork: selectedInquiryVariant.physicalWork,

        from: inquiryData.from || '',
        to: inquiryData.to || '',
        timezone: getTImeZoneStr(),
        tripReason: inquiryData.tripReason || TripReason.OTHER,
        schoolName: inquiryData.school || '',
        destination: inquiryData.studyCountry || '',
        guestEmail: inquirySubject.email || '',
        visitDate: inquiryData.from || '',
        inquirySubjects: [
            {
                firstName: inquirySubject.firstName || '',
                lastName: inquirySubject.lastName || '',
                birthDate: inquirySubject.birthDate || '',
                address: {
                    addressLine1: inquirySubject.address || '',
                    addressLine2: null,
                    addressLine3: null,
                    postcode: inquirySubject.postCode || '',
                    city: inquirySubject.city || '',
                    country: inquiryData.residencyCountry || '',
                },
                main: true,
                email: inquirySubject.email || '',
                phone: inquirySubject.phone || ''
            }
        ],
        reference: null,
        source: null
    }
}

const getConsents = (consents: IInquiryConsents): IConsentInput[] => {
    return [
        {
            type: 'private_policy_consent',
            consentStatement: consents.privacyPolicy || '',
            didConsent: !!consents.privacyPolicy,
        },
        {
            type: 'insurance_docs_consent',
            consentStatement: consents.insuranceDocs || '',
            didConsent: !!consents.insuranceDocs,
        },
        {
            type: 'requirements_and_needs_consent',
            consentStatement: consents.requirementsAndNeeds || '',
            didConsent: !!consents.requirementsAndNeeds,
        },
        {
            type: 'terms_and_conditions_consent',
            consentStatement: consents.termsAndConditions || '',
            didConsent: !!consents.termsAndConditions,
        },
        {
            type: 'email_marketing_consent',
            consentStatement: consents.emailMarketing || '',
            didConsent: !!consents.emailMarketing,
        },
        {
            type: 'phone_marketing_consent',
            consentStatement: consents.phoneMarketing || '',
            didConsent: !!consents.phoneMarketing,
        }
    ]
}


const axaInsuranceEpic = combineEpics(
    addInquiryEpic,
    generatePaymentLinkEpic,
    updateInquiryEpic,
    checkInquiryUpdateStatusEpic,
    getInquiryEpic,
    generateInquiryEmailEpic,
);

export default axaInsuranceEpic;
