import React from 'react';
import {BehaviorSubject} from 'rxjs';
import {Form, FormControlChangeType, IFormConfig, Translation} from "educat-common-web";
import {useEffect, useState, useMemo} from "react";
import {filter, tap} from 'rxjs/operators';
import {inquirySubjectFormConfig} from "./inquirySubjectFormConfig";
import {useDispatch, useSelector} from "react-redux";
import {
    changeCurrentStep,
    changeInquirySubject,
    InquiryFormStep,
    IInquirySubject
} from "../../../store/reducers/axaInsuranceSlice";
import {ValueType} from "../InquiryDataStep";
import {inquirySubjectSelector} from "../../../store/selectors/axaInsuranceSelectors";
import moment from "moment/moment";

export interface IInquirySubjectFormValue {
    firstName: string | null;
    lastName: string | null;
    birthDate: string | null;
    address: string | null;
    postCode: string | null;
    city: string | null;
    email: string | null;
    phone: string | null;
}

interface IInquirySubjectStepProps {}

const InquirySubjectStep: React.FC<IInquirySubjectStepProps> = () => {
    const onValueStateChange$ = useMemo(() => new BehaviorSubject<ValueType<IInquirySubjectFormValue> | null>(null), []),
        inquirySubject = useSelector(inquirySubjectSelector),
        [value, setValue] = useState<IInquirySubjectFormValue | null>(inquirySubject ? {
            firstName: inquirySubject?.firstName || null,
            lastName: inquirySubject?.lastName  || null,
            birthDate: inquirySubject?.birthDate || null,
            address: inquirySubject?.address || null,
            postCode: inquirySubject?.postCode || null,
            city: inquirySubject?.city || null,
            email: inquirySubject?.email || null,
            phone: inquirySubject?.phone || null,
        } : null),
        [formConfig, setFormConfig] = useState<typeof IFormConfig | null>(null),
        dispatch = useDispatch();

    const redirectToPrevStep = () => dispatch(changeCurrentStep(InquiryFormStep.INQUIRY_DATA));
    const setForm = (): void => {
        const formConfig = inquirySubjectFormConfig(value, redirectToPrevStep);
        setFormConfig(formConfig);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setForm();
    }, []);

    useEffect(() => {
    }, [inquirySubject]);

    useEffect(() => {
        const subscription = onValueStateChange$.pipe(
            filter((data: any) => data && data.changeType === FormControlChangeType.User),
            tap((data: any) => setValue(data.value)),
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        }
    }, [onValueStateChange$]);


    const onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    const updateData = () => {
        if (!value ||
            !value.firstName ||
            !value.lastName ||
            !value.address ||
            !value.postCode ||
            !value.city ||
            !value.email ||
            !value.phone
        ) {
            return;
        }
        const inquirySubject: IInquirySubject = {
            firstName: value.firstName,
            lastName: value.lastName,
            birthDate: moment(value.birthDate).format('YYYY-MM-DD'),
            address: value.address,
            postCode: value.postCode,
            city: value.city,
            email: value.email,
            phone: value.phone,
        };

        dispatch(changeInquirySubject(inquirySubject));
        dispatch(changeCurrentStep(InquiryFormStep.INQUIRY_VARIANT));
    }

    return (
        <div className="inquiry-form-card policyholder-card">
            <h1 className="inquiry-step-title">
                <Translation text={'inquiry.inquirySubject.description'}/>
            </h1>

            {formConfig && <Form config={formConfig}
                                 onValueStateChange={onValueStateChange}
                                 value={value}
                                 submitForm={updateData}
                                 controlName={'inquirySubjectDataForm'}/>
            }
        </div>
    )
}

export default InquirySubjectStep;
