import React from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {Translation} from "educat-common-web";
import {IInquiryOutput} from "../../../../model/inquiry";
import {getInquiryDurationFromInquiryData} from "../../inquiryUtil";
import {IInquiryData, IInquirySubject} from "../../../../store/reducers/axaInsuranceSlice";
import {useSelector} from "react-redux";
import {
    inquiryDataSelector,
    inquirySelector,
    inquirySubjectSelector
} from "../../../../store/selectors/axaInsuranceSelectors";

interface ISummaryDataProps {
}

const SummaryData: React.FC<ISummaryDataProps> = (props) => {
    const {t} = useTranslation(),
        inquiry: IInquiryOutput | null = useSelector(inquirySelector),
        inquirySubject: IInquirySubject | null = useSelector(inquirySubjectSelector),
        inquiryData: IInquiryData | null = useSelector(inquiryDataSelector);


    const formatPhoneNumber = (phoneNumber: string | null | undefined): string => {
        if (!phoneNumber) return '-';
        return phoneNumber.replace(/\(|\)/g, '');
    }

    const count: number = getInquiryDurationFromInquiryData(inquiryData) || 0;

    const renderInquiryDetails = (label: string, value: string | null | undefined) => {
        return (
            <div className="inquiry-summary-item">
                <p className="inquiry-summary-label">
                    <Translation text={`inquiry.inquirySummary.labels.${label}`}/>
                </p>
                <p className="inquiry-summary-value">{value ? value : '-'}</p>
            </div>
        )
    }

    return (
        <div className="inquiry-summary">
            <div className="inquiry-summary-row">
                {renderInquiryDetails('firstName', inquirySubject?.firstName)}
                {renderInquiryDetails('lastName', inquirySubject?.lastName)}
            </div>

            <div className="inquiry-summary-row">
                {renderInquiryDetails('birthDate', moment(inquirySubject?.birthDate).format('DD-MM-yyyy'))}
                {renderInquiryDetails('address', inquirySubject?.address)}
            </div>

            <div className="inquiry-summary-row">
                {renderInquiryDetails('postCode', inquirySubject?.postCode)}
                {renderInquiryDetails('city', inquirySubject?.city)}
            </div>

            <div className="inquiry-summary-row">
                {renderInquiryDetails('email', inquirySubject?.email)}
                {renderInquiryDetails('phone', formatPhoneNumber(inquirySubject?.phone))}
            </div>

            <div className="inquiry-summary-row">
                {renderInquiryDetails('residencyCountry', t(`countries.${inquiryData?.residencyCountry?.toLowerCase()}`))}
                {renderInquiryDetails('studyCountry', t(`countries.${inquiry?.destination?.toLowerCase()}`))}
            </div>

            <div className="inquiry-summary-row">
                {renderInquiryDetails('insuranceDate', moment(inquiry?.from).format('DD-MM-yyyy'))}
                {renderInquiryDetails(
                    'insuranceDuration',
                    t('inquiry.inquirySummary.labels.month', { count: count })
                )}
            </div>
        </div>
    )
}

export default SummaryData;
