import React, {Fragment, useEffect, useState} from 'react';
import {Translation, deepCloneObject} from "educat-common-web";
import {useDispatch, useSelector} from "react-redux";
import {consentsSelector} from "../../../../store/selectors/axaInsuranceSelectors";
import {changeConsents, ConsentType, IInquiryConsents} from "../../../../store/reducers/axaInsuranceSlice";
import ConsentCheckbox from "./ConsentCheckbox";
import {useTranslation} from "react-i18next";


interface ISummaryConsentsProps {}

interface IConsentCheckbox {
    name: ConsentType;
    statement: string;
    statementLabel: string;
    isRequired: boolean;
}

const ConsentStatement = {
    privacyPolicy: 'Zapoznałem się z Polityką Prywatności.',
    insuranceDocs: 'Przed zawarciem umowy ubezpieczenia otrzymałem Szczególne Warunki Ubezpieczenia EduCat, Broszurę informacyjną – przetwarzanie danych osobowych i dokument zawierający informacje o produkcie ubezpieczeniowym oraz zobowiązuję się do zapoznania wszystkich ubezpieczonych z treścią tych dokumentów.',
    requirementsAndNeeds: 'Niniejsza umowa ubezpieczenia jest zgodna z moimi wymaganiami i potrzebami ubezpieczeniowymi.',
    termsAndConditions: 'Przed zawarciem umowy ubezpieczenia zapoznałem się z Regulaminem zawierania umów na odległość.',
    emailMarketing: 'Wyrażam zgodę na wykorzystanie podanego przez mnie adresu e-mail do celów marketingu bezpośredniego produktów i usług EduCat.',
    phoneMarketing: 'Wyrażam zgodę na wykorzystanie podanego przez mnie numer telefonu do celów marketingu bezpośredniego produktów i usług EduCat.',
}

const consentsCheckboxes: IConsentCheckbox[] = [
    {
        name: ConsentType.privacyPolicy,
        statement: ConsentStatement.privacyPolicy,
        statementLabel: `Zapoznałem się z <a href="${process.env.REACT_APP_PRIVACY_POLICY_URL}" target="_blank">Polityką Prywatności</a>.`,
        isRequired: true,
    },
    {
        name: ConsentType.insuranceDocs,
        statement: ConsentStatement.insuranceDocs,
        statementLabel: `Przed zawarciem umowy ubezpieczenia otrzymałem <a href="${process.env.REACT_APP_SWU_URL}" target="_blank">Szczególne Warunki Ubezpieczenia EduCat</a>, <a href="${process.env.REACT_APP_RODO_URL}" target="_blank">Broszurę informacyjną – przetwarzanie danych osobowych</a> i <a href="${process.env.REACT_APP_INSURANCE_INFO_URL}" target="_blank">dokument zawierający informacje o produkcie ubezpieczeniowym</a> oraz zobowiązuję się do zapoznania wszystkich ubezpieczonych z treścią tych dokumentów.`,
        isRequired: true,
    },
    {
        name: ConsentType.requirementsAndNeeds,
        statement: ConsentStatement.requirementsAndNeeds,
        statementLabel: `Niniejsza umowa ubezpieczenia jest zgodna z moimi wymaganiami i potrzebami ubezpieczeniowymi.`,
        isRequired: true,
    },
    {
        name: ConsentType.termsAndConditions,
        statement: ConsentStatement.termsAndConditions,
        statementLabel: `Przed zawarciem umowy ubezpieczenia zapoznałem się z <a href="${process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}" target="_blank">Regulaminem zawierania umów na odległość</a>.`,
        isRequired: true,
    },
    {
        name: ConsentType.emailMarketing,
        statement: ConsentStatement.emailMarketing,
        statementLabel: `Wyrażam zgodę na wykorzystanie podanego przez mnie adresu e-mail do celów marketingu bezpośredniego produktów i usług EduCat.`,
        isRequired: false,
    },
    {
        name: ConsentType.phoneMarketing,
        statement: ConsentStatement.phoneMarketing,
        statementLabel: `Wyrażam zgodę na wykorzystanie podanego przez mnie numer telefonu do celów marketingu bezpośredniego produktów i usług EduCat.`,
        isRequired: false,
    },
]


const SummaryConsents: React.FC<ISummaryConsentsProps> = (props) => {
    const dispatch = useDispatch(),
        {t} = useTranslation(),
        consents: IInquiryConsents | null = useSelector(consentsSelector),
        [isAllConsentsChecked, setIsAllConsentsChecked] = useState<boolean>(false);


    useEffect(() => {
        if (!consents) dispatch(changeConsents(getEmptyInquiryConsent()));
    }, []);

    useEffect(() => {
        if (consents && consents.insuranceDocs && consents.privacyPolicy && consents.requirementsAndNeeds &&
            consents.termsAndConditions && consents.emailMarketing && consents.phoneMarketing) {
            setIsAllConsentsChecked(true);
        } else {
            setIsAllConsentsChecked(false);
        }
    }, [consents]);

    const getEmptyInquiryConsent = (): IInquiryConsents => {
        return {
            privacyPolicy : null,
            insuranceDocs: null,
            requirementsAndNeeds: null,
            termsAndConditions: null,
            emailMarketing: null,
            phoneMarketing: null,
        }
    }


    const handleConsentChange = (consentType: ConsentType, isChecked: boolean) => {
        const newConsents: IInquiryConsents = deepCloneObject(consents);
        newConsents[consentType] = isChecked ? ConsentStatement[consentType] : null;
        dispatch(changeConsents(newConsents));
    }

    const handleAllConsentsChange = (isChecked: boolean) => {
        let newConsents: IInquiryConsents = getEmptyInquiryConsent()
        if (isChecked) {
            newConsents = {
                privacyPolicy : ConsentStatement.privacyPolicy,
                insuranceDocs: ConsentStatement.insuranceDocs,
                requirementsAndNeeds: ConsentStatement.requirementsAndNeeds,
                termsAndConditions: ConsentStatement.termsAndConditions,
                emailMarketing: ConsentStatement.emailMarketing,
                phoneMarketing: ConsentStatement.phoneMarketing,
            }
        }
        dispatch(changeConsents(newConsents));
    }

    const renderConsentsCheckbox = (consentsCheckbox: IConsentCheckbox) => {
        const isChecked: boolean = consents && consentsCheckbox.name ? !!consents[consentsCheckbox.name] : false;

        return (
            <ConsentCheckbox key={consentsCheckbox.name}
                             name={consentsCheckbox.name}
                             class={consentsCheckbox.isRequired ? 'required' : ''}
                             statement={consentsCheckbox.statement}
                             statementLabel={consentsCheckbox.statementLabel}
                             isChecked={isChecked}
                             onChange={(checkedVal: boolean) => handleConsentChange(consentsCheckbox.name, checkedVal)} />
        )
    }

    return (
        <div className="consents-container">
            <h2 className="inquiry-step-title"><Translation text={'inquiry.inquirySummary.consents.title'}/></h2>

            <ConsentCheckbox name={'all'}
                             statementLabel={t('inquiry.inquirySummary.consents.all')}
                             isChecked={isAllConsentsChecked}
                             onChange={(checkedVal: boolean) => handleAllConsentsChange(checkedVal)}/>

            <div className="consent-wrapper">
                {consentsCheckboxes.map((consentsCheckbox: IConsentCheckbox) => renderConsentsCheckbox(consentsCheckbox))}
            </div>
        </div>
    )
}

export default SummaryConsents;
