import React from 'react';
import {useDispatch} from "react-redux";
import { resetToInitialInsuranceState} from "../../store/reducers/axaInsuranceSlice";
import {Redirect} from "react-router";

interface IStateResetHostProps {}

const StateResetHost: React.FC<IStateResetHostProps> = () => {
    const dispatch = useDispatch();
    dispatch(resetToInitialInsuranceState());

    return (<Redirect to={"/"}/>)
}

export default StateResetHost;
