import {
    destinationCountryLabelsList,
} from 'educat-common-web';
import {IInquiryData, ISelectedInquiryVariant} from "../../store/reducers/axaInsuranceSlice";
import {IInquiryVariant} from "../../model/inquiryVariant";
import {IPrice} from "./InquiryVariantStep/variantsStructureData";
import {IInquiryOutput} from "../../model/inquiry";
import {getInquiryDurationFromInquiryData} from "./inquiryUtil";

export const getMonthlyPriceObjFromInquiry = (inquiry: IInquiryOutput | null, inquiryData: IInquiryData | null): IPrice | null => {
    if (!inquiry || !inquiryData) return null;

    const monthlyPrice: number | null = getMonthlyPriceRawFromInquiry(inquiry, inquiryData);

    if (!monthlyPrice) return null;

    return {
        amount: monthlyPrice.toFixed(0),
        currency: {
            code: inquiry.inquiryVariant.basePrice.currency.code
        }
    }
}

export const getMonthlyPriceObjFromSelectedInquiryVariant = (
    selectedInquiryVariant: ISelectedInquiryVariant | null,
    inquiryVariants: IInquiryVariant[] | null,
    inquiryData: IInquiryData | null): IPrice | null => {
    if (!selectedInquiryVariant || !inquiryVariants || !inquiryData) return null;

    const monthlyPrice: number | null = getMonthlyPriceRawFromSelectedInquiryVariant(selectedInquiryVariant, inquiryVariants, inquiryData);

    if (!monthlyPrice) return null;

    return {
        amount: monthlyPrice.toFixed(0),
        currency: {
            code: "EUR"
        }
    }
}

export const getMonthlyPriceRawFromInquiry = (inquiry: IInquiryOutput | null, inquiryData: IInquiryData | null): number | null => {
    if (!inquiry || !inquiry.inquiryVariant || !inquiryData || !inquiryData.studyCountry) return null;

    const isAlcoholClause: boolean = inquiry.alcoholClause,
        isWorkClause: boolean = inquiry.physicalWork,
        alcoholClauseValue: number = inquiry.inquiryVariant.alcoholClauseValue,
        workClauseValue: number = inquiry.inquiryVariant.physicalWorkValue;

    const monthlyPriceObj: IPrice | null = getPriceByCountryCode(inquiry.inquiryVariant, inquiryData);

    if (!monthlyPriceObj) return null;

    const monthlyPrice: number = Number.parseInt(monthlyPriceObj.amount);
    let total: number = monthlyPrice;

    if (isWorkClause) total = total + ( monthlyPrice * workClauseValue );
    if (isAlcoholClause) total =  total + ( monthlyPrice * alcoholClauseValue );

    return total;
}

export const getMonthlyPriceRawFromSelectedInquiryVariant = (
    selectedInquiryVariant: ISelectedInquiryVariant,
    inquiryVariants: IInquiryVariant[],
    inquiryData: IInquiryData): number | null => {

    const inquiryVariant: IInquiryVariant | undefined = inquiryVariants.find((variant: IInquiryVariant) => variant.id === selectedInquiryVariant.id)

    if (!inquiryVariant) return null;

    const isAlcoholClause: boolean = selectedInquiryVariant.alcoholClause,
        isWorkClause: boolean = selectedInquiryVariant.physicalWork,
        alcoholClauseValue: number = inquiryVariant.alcoholClauseValue,
        workClauseValue: number = inquiryVariant.physicalWorkValue;

    const monthlyPriceObj: IPrice | null = getPriceByCountryCode(inquiryVariant, inquiryData);

    if (!monthlyPriceObj) return null;

    const monthlyPrice: number = Number.parseInt(monthlyPriceObj.amount);
    let total: number = monthlyPrice;

    if (isWorkClause) total = total + ( monthlyPrice * workClauseValue );
    if (isAlcoholClause) total =  total + ( monthlyPrice * alcoholClauseValue );

    return total;
}

export const getTotalPriceObjFromSelectedInquiryVariant = (
    selectedInquiryVariant: ISelectedInquiryVariant | null,
    inquiryVariants: IInquiryVariant[] | null,
    inquiryData: IInquiryData | null): IPrice | null => {
    if (!selectedInquiryVariant || !inquiryVariants || !inquiryData) return null;

    const monthlyPrice: number | null = getMonthlyPriceRawFromSelectedInquiryVariant(selectedInquiryVariant, inquiryVariants, inquiryData);
    const duration:  number | null = getInquiryDurationFromInquiryData(inquiryData);

    if (!monthlyPrice || !duration) return null;

    return {
        amount: (monthlyPrice * duration).toFixed(0),
        currency: {
            code: "EUR"
        }
    }
}

export const getTotalPriceObjFromInquiry = (inquiry: IInquiryOutput | null, inquiryData: IInquiryData | null): IPrice | null => {
    if (!inquiry) return null;

    const monthlyPrice: number | null = getMonthlyPriceRawFromInquiry(inquiry, inquiryData);
    const duration: number | null = getInquiryDurationFromInquiryData(inquiryData);

    if (!monthlyPrice || !duration) return null;

    return {
        amount: (monthlyPrice * duration).toFixed(0),
        currency: {
            code: inquiry.inquiryVariant.basePrice.currency.code
        }
    }
}

export const getPriceByCountryCode = (inquiryVariant: IInquiryVariant | null, inquiryData: IInquiryData | null): IPrice | null => {
    if (!inquiryVariant || !inquiryData || !inquiryData.studyCountry) return null;

    return getIsEuropeTypeByCountryCode(inquiryData.studyCountry) ?
        inquiryVariant.europeAdditionalPrice :
        inquiryVariant.worldAdditionalPrice;
}

export const getIsEuropeTypeByCountryCode = (countryCode: string): boolean => {
    let destinationCountry = destinationCountryLabelsList.find((country: any) => country?.value === countryCode );
    return destinationCountry.type === 'europe';
}

