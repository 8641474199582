import {createSelector} from '@reduxjs/toolkit';
import { RootState } from "../reducers";
import {IAxaInsuranceState, IInquiryConsents, IInquiryData, IInquirySubject} from "../reducers/axaInsuranceSlice";

export const selectAxaInsurance = (state: RootState): IAxaInsuranceState => {
    return state.axaInsurance;
};

export const currentStepSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.currentStep
);

export const formStepsSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.formSteps
);

export const inquiryDataSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.inquiryData
);

export const isInquiryDataValidSelector = createSelector(
    [inquiryDataSelector],
    (inquiryData: IInquiryData | null): boolean => {
        return !!(inquiryData?.from && inquiryData?.to && inquiryData?.residencyCountry &&
            inquiryData?.studyCountry && inquiryData?.school && inquiryData?.tripReason);
    }
);

export const inquirySubjectSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.inquirySubject
);

export const isInquirySubjectDataValidSelector = createSelector(
    [inquirySubjectSelector],
    (inquirySubject: IInquirySubject | null): boolean => {
        return !!(inquirySubject?.firstName && inquirySubject?.lastName && inquirySubject?.birthDate && inquirySubject?.address &&
            inquirySubject?.postCode && inquirySubject?.city && inquirySubject?.email && inquirySubject?.phone);
    }
);

export const isInquiryVariantStepValidSelector = createSelector(
    [isInquiryDataValidSelector, isInquirySubjectDataValidSelector],
    (isInquiryDataValid: boolean, isInquirySubjectValid: boolean): boolean => {
        return isInquiryDataValid && isInquirySubjectValid;
    }
);

export const isInquirySummaryStepValidSelector = createSelector(
    [isInquiryDataValidSelector, isInquirySubjectDataValidSelector, isInquiryVariantStepValidSelector],
    (isInquiryDataValid: boolean, isInquirySubjectValid: boolean, isInquiryVariantStepValid: boolean): boolean => {
        return isInquiryDataValid && isInquirySubjectValid && isInquiryVariantStepValid;
    }
);

export const inquiryIdSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.inquiryId
);

export const isAxaInsuranceLoadingSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.isLoading
);

export const isFormInitializedSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.initialized
);

export const wizardHeadStepIndexSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.wizardHeadStepIndex
);

export const inquirySelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.inquiry
);

export const consentsSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.consents
);

export const isConsentsValidSelector = createSelector(
    [consentsSelector],
    (consents: IInquiryConsents | null): boolean => {
        return (!!consents && !!consents.privacyPolicy && !!consents.insuranceDocs &&
            !!consents.requirementsAndNeeds && !!consents.termsAndConditions);
    }
)

export const inquiryVariantSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.inquiryVariant
)

export const isAdminPanelSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.isAdminPanel
)

export const adminAuthTokenSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.adminAuthToken
)
