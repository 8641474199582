import React from 'react';
import styles from '../styles.module.scss';
import {IInquiryVariant} from "../../../../model/inquiryVariant";
import Price from "../../shared/Price";
import {useSelector} from "react-redux";
import {inquiryDataSelector} from "../../../../store/selectors/axaInsuranceSelectors";
import {getPriceByCountryCode} from "../../priceUtil";
import {Translation} from "educat-common-web";
import {IInquiryData} from "../../../../store/reducers/axaInsuranceSlice";

interface IVariantTileProps {
    inquiryVariant: IInquiryVariant | null;
}

const VariantTile: React.FC<IVariantTileProps> = (props: IVariantTileProps) => {
    const inquiryData: IInquiryData | null  = useSelector(inquiryDataSelector);

    return (
        <div className={styles.variant}>
            <h2 className={styles.variantName}>
                { props.inquiryVariant ? props.inquiryVariant.name : '-' }
            </h2>

            { inquiryData && <Price dec={0} priceObj={getPriceByCountryCode(props.inquiryVariant, inquiryData)}/> }

            <p className={styles.variantDuration}>
                <Translation text={'inquiry.inquiryVariant.coverageDuration'}/>
            </p>
        </div>
    );
};

export default VariantTile;
