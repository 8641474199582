import React, {useState} from 'react';
import styles from '../../../styles.module.scss';
import {useTranslation} from "react-i18next";

interface IModalProps {
    labelKey: string;
}

const Modal: React.FC<IModalProps> = (props: IModalProps) => {
    const {t} = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div>
            <span className={styles.tooltip} onClick={() => setIsOpen(true)}>i</span>
            <div className={`${styles.modalWrapper} ${isOpen ? styles.isOpen : ''}`}>
                <div className={styles.modal}>
                    <div className={styles.btnClose} onClick={() => setIsOpen(false)}>
                        <i className={styles.iconClose}/>
                    </div>
                    <div className={styles.modalTitle}>
                        { t(`inquiry.inquiryVariant.details.${props.labelKey}`)}
                    </div>
                    <div className={styles.modalContent}>
                        { t(`inquiry.inquiryVariant.tooltips.${props.labelKey}`) }
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Modal;
