import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IInquiryVariant} from "../../model/inquiryVariant";
import {initApiCall} from "../../utils/reduxHelpers";

export interface IInquiryVariantListState {
    inquiryVariants: IInquiryVariant[] | null;
    error: string | null;
    isLoading: boolean,
    initialized: boolean,
}

export interface IChangeInquiryVariants {
    inquiryVariants: IInquiryVariant[] | null;
}

export interface IChangeIsLoading {
    isLoading: boolean;
}

export interface IChangeError {
    error: string | null;
}

export interface IChangeIsInitialized {
    isInitialized: boolean;
}

const initialState: IInquiryVariantListState = {
    inquiryVariants: null,
    isLoading: false,
    initialized: false,
    error: null,
};

const inquiryVariantListSlice = createSlice({
    name: "inquiryVariantList",
    initialState: initialState,
    reducers: {
        fetchInquiryVariants: (state: IInquiryVariantListState) => initApiCall(state),
        changeInquiryVariants: {
            reducer: (state: IInquiryVariantListState, action: PayloadAction<IChangeInquiryVariants>) => {
                return {
                    ...state,
                    inquiryVariants: action.payload.inquiryVariants
                }
            },
            prepare(inquiryVariants: IInquiryVariant[] | null) {
                return {
                    payload: {inquiryVariants}
                }
            }
        },
        changeIsLoading: {
            reducer: (state: IInquiryVariantListState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading
                }
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading}
                }
            }
        },
        changeIsInitialized: {
            reducer: (state: IInquiryVariantListState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    ...state,
                    initialized: action.payload.isInitialized,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: { isInitialized }
                };
            }
        },
        changeInquiryVariantsError: {
            reducer: (state: IInquiryVariantListState, action: PayloadAction<IChangeError>) => {

                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error}
                };
            }
        },
    }
});

export const {
    fetchInquiryVariants,
    changeInquiryVariants,
    changeIsLoading,
    changeIsInitialized,
    changeInquiryVariantsError,
} = inquiryVariantListSlice.actions;

export default inquiryVariantListSlice.reducer;
