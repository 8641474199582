import React from 'react';
import styles from '../../styles.module.scss';
import {useTranslation} from "react-i18next";
import {Tooltip, TooltipDirection} from "educat-common-web";
import Modal from "./Modal";

interface ICustomTooltipProps {
    labelKey: string;
    isMobile: boolean | null;
}

const CustomTooltip: React.FC<ICustomTooltipProps> = (props: ICustomTooltipProps) => {
    const {t} = useTranslation();

    const renderTooltipOrModal = () => {
        if (props.isMobile) {
            return (<Modal labelKey={props.labelKey}/>)
        }

        return (
            <Tooltip content={t(`inquiry.inquiryVariant.tooltips.${props.labelKey}`)}
                     direction={TooltipDirection.RIGHT}>
                <span aria-hidden="true" className={styles.tooltip}>i</span>
            </Tooltip>
        )
    }

    return renderTooltipOrModal();
};

export default CustomTooltip;
