import React from 'react';
import {Switch} from "react-router-dom";
import InquiryFormHost from "../InquiryFormHost";
import {Redirect, Route} from "react-router";
import {InquiryFormStep} from "../../store/reducers/axaInsuranceSlice";
import {useSelector} from "react-redux";
import {activeLanguageSelector, Toast} from "educat-common-web";
import Footer from "../Footer";
import SuccessPage from "../SuccessPage";
import StateResetHost from "../StateResetHost";
import {isAdminPanelSelector} from "../../store/selectors/axaInsuranceSelectors";

interface PanelHostProps {}

const PanelHost: React.FC<PanelHostProps> = () => {
    const lng = useSelector(activeLanguageSelector),
        isAdminPanel = useSelector(isAdminPanelSelector);

    return (
        <div>
            <Switch>
                <Route path={`/form-reset`} component={StateResetHost} />
                <Route path={`/:lng/success`} component={SuccessPage} />
                <Route path={`/:lng/inquiry/:step`} component={InquiryFormHost} />
                <Route component={() => (<Redirect to={`/${lng}/inquiry/${InquiryFormStep.INQUIRY_DATA}`}/>)}/>
                <Route component={() => (<Redirect to={`/${lng}/inquiry/${InquiryFormStep.INQUIRY_SUBJECT}`}/>)}/>
                <Route component={() => (<Redirect to={`/${lng}/inquiry/${InquiryFormStep.INQUIRY_VARIANT}`}/>)}/>
                <Route component={() => (<Redirect to={`/${lng}/inquiry/${InquiryFormStep.INQUIRY_SUMMARY}`}/>)}/>
                <Route component={() => (<Redirect to={'/'}/>)}/>
            </Switch>
            {!isAdminPanel ? <Footer /> : null}
            <Toast/>
        </div>
    );
};

export default PanelHost;
