import moment from "moment/moment";
import {IInquiryData, ISelectedInquiryVariant} from "../../store/reducers/axaInsuranceSlice";
import {IInquiryVariant} from "../../model/inquiryVariant";

export const getSelectedVariantNameFromInquiryVariants = (
    selectedInquiryVariant: ISelectedInquiryVariant | null, inquiryVariants: IInquiryVariant[] | null) => {
    if (!selectedInquiryVariant || !inquiryVariants) return;

    const variant: IInquiryVariant | undefined = inquiryVariants.find((variant: IInquiryVariant) => variant.id === selectedInquiryVariant.id)

    return variant ? variant.name : '-';
}
export const getInquiryDurationFromInquiryData = (inquiryData: IInquiryData | null): number | null => {
    if (!inquiryData || !inquiryData.from || !inquiryData.to) return null;

    const dateFrom = moment(inquiryData.from),
        dateTo = moment(inquiryData.to),
        days: number = dateTo.diff(dateFrom, 'days');

    return Math.round(days / 30);
}

export const getInquiryDurationStrFromInquiryData = (inquiryData: IInquiryData | null): string | null => {
    const durationNo: number | null = getInquiryDurationFromInquiryData(inquiryData);
    if (!durationNo) return null;

    return durationNo.toFixed(0)
}

export const getInquiryEndDateISOString = (startDate: string, durationStr: string): string | null => {
    if (!startDate || !durationStr) return '';

    const duration: number= Number.parseInt(durationStr);

    if (!duration) return null;
    const startDateMoment = moment(startDate);
    return moment(startDateMoment).add((duration * 30), 'day').toISOString();
}