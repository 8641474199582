import React from 'react';
import {Translation} from "educat-common-web";
import Price from "../Price";
import {IInquiryData, ISelectedInquiryVariant} from "../../../../store/reducers/axaInsuranceSlice";
import {useSelector} from "react-redux";
import {getTotalPriceObjFromInquiry, getTotalPriceObjFromSelectedInquiryVariant} from "../../priceUtil";
import {
    inquiryDataSelector,
    inquirySelector,
    inquiryVariantSelector,
    isAdminPanelSelector,
} from "../../../../store/selectors/axaInsuranceSelectors";
import {IInquiryVariant} from "../../../../model/inquiryVariant";
import {inquiryVariantsSelector} from "../../../../store/selectors/inquiryVariantsListSelectors";
import {IPrice} from "../../InquiryVariantStep/variantsStructureData";
import {IInquiryOutput} from "../../../../model/inquiry";
import {useTranslation} from "react-i18next";


export enum PurchaseSummaryType {
    Variant = 'variant',
    Summary = 'summary',
}
interface IPurchaseSummaryProps {
    onPrev: () => void;
    onNext: () => void;
    canNext?: boolean;
    type: PurchaseSummaryType;
}

const PurchaseSummary: React.FC<IPurchaseSummaryProps> = (props) => {
    const {t} = useTranslation(),
        inquiry: IInquiryOutput | null = useSelector(inquirySelector),
        inquiryData: IInquiryData | null = useSelector(inquiryDataSelector),
        inquiryVariants: IInquiryVariant[] | null = useSelector(inquiryVariantsSelector),
        selectedInquiryVariant: ISelectedInquiryVariant | null = useSelector(inquiryVariantSelector),
        isAdminPanel = useSelector(isAdminPanelSelector);

    const canNext: boolean = props.canNext === undefined ? true : props.canNext;

    const priceObj: IPrice | null = props.type === PurchaseSummaryType.Variant ?
        getTotalPriceObjFromSelectedInquiryVariant(selectedInquiryVariant, inquiryVariants, inquiryData) :
        getTotalPriceObjFromInquiry(inquiry, inquiryData);

    const getSelectedClausesLabel = (): string => {
        if (!inquiry) {
            return '';
        }

        const isAlcoholClauseIncluded: boolean = inquiry.alcoholClause,
            isWorkClauseIncluded: boolean = inquiry.physicalWork;

        const clauses: string[] = [];
        if (isAlcoholClauseIncluded) clauses.push(t('inquiry.purchaseSummary.alcoholClause'));
        if (isWorkClauseIncluded) clauses.push(t('inquiry.purchaseSummary.workClause'));

        return clauses.length ? `( + ${clauses} )` : '';
    }

    const renderVariantRowSummary = () => {
        if (props.type === PurchaseSummaryType.Variant) return;

        return (
            <div className="payment-summary-row">
                <div className="variant-name-container">
                    { inquiry?.inquiryVariant?.name || '-' }
                    <span className="variant-name-clauses">{ getSelectedClausesLabel() }</span>
                </div>
            </div>
        )
    }

    return (
        <div className="payment-summary-card">
            <div className="payment-summary">
                { renderVariantRowSummary() }
                <div className="payment-summary-row">
                    <div className="variant-name-container">
                        <Translation text={'inquiry.purchaseSummary.total'}/>
                    </div>
                    <div className="variant-price-container">
                        <Price dec={2} priceObj={priceObj}/>
                    </div>
                </div>
            </div>

            <div className="button-container">
                <button className="btn btn-theme btn-back"
                        onClick={() => props.onPrev()}>
                    <Translation text="buttons.previous" />
                </button>
                <button className="btn btn-theme"
                        onClick={() => props.onNext()}
                        disabled={!canNext}>
                    <Translation text={isAdminPanel && props.type === PurchaseSummaryType.Summary ? "buttons.sendInquiryEmail" : "buttons.next"} />
                </button>
            </div>
        </div>
    )
}

export default PurchaseSummary;
