import React, {useEffect, useState} from 'react';
import {activeLanguageSelector, Translation} from 'educat-common-web';
import {useDispatch, useSelector} from 'react-redux';
import {
    currentStepSelector,
    formStepsSelector,
    inquiryIdSelector,
    isAdminPanelSelector,
    isInquiryDataValidSelector,
    isInquirySubjectDataValidSelector,
    isInquirySummaryStepValidSelector,
    isInquiryVariantStepValidSelector,
    wizardHeadStepIndexSelector
} from '../../../store/selectors/axaInsuranceSelectors';
import {
    changeCurrentStep,
    changeInquiryData,
    changeInquirySubject,
    changeWizardHeadStepIndex,
    getInquiry,
    IInquiryData,
    InquiryFormStep,
    IInquirySubject,
    resetToInitialInsuranceState,
    setIsAdminPanel,
    setAdminAuthToken
} from '../../../store/reducers/axaInsuranceSlice';
import {useHistory} from "react-router-dom";

interface IWizardStep {
    name: InquiryFormStep;
    isActive: boolean;
    isEnabled: boolean;
    isValid: boolean;
    isHead: boolean;
}

interface IInquiryWizardProps {}

const StepWizard: React.FC<IInquiryWizardProps> = () => {
    const [steps, setSteps] = useState<IWizardStep[]>([]),
        dispatch = useDispatch(),
        history = useHistory(),
        formSteps = useSelector(formStepsSelector),
        inquiryId = useSelector(inquiryIdSelector),
        currentStep = useSelector(currentStepSelector),
        isInquiryDataValid = useSelector(isInquiryDataValidSelector),
        isInquirySubjectDataValid = useSelector(isInquirySubjectDataValidSelector),
        isInquiryVariantValid = useSelector(isInquiryVariantStepValidSelector),
        isInquirySummaryStepValid = useSelector(isInquirySummaryStepValidSelector),
        wizardHeadStepIndex = useSelector(wizardHeadStepIndexSelector),
        activeLanguage = useSelector(activeLanguageSelector);

    const getStepIndex = (stepName: InquiryFormStep): number => {
        const index = formSteps.findIndex((step: InquiryFormStep) => step === stepName);
        if (index === -1) {
            // toDo add alert once no step found
            throw new Error('[StepManagerService]: Invalid step index.')
        }

        return index;
    }
    const isStepValid = (insuranceFormStep: InquiryFormStep): boolean => {
        switch (insuranceFormStep) {
            case InquiryFormStep.INQUIRY_DATA:
                       return isInquiryDataValid;

            case InquiryFormStep.INQUIRY_SUBJECT:
                   return isInquirySubjectDataValid;

            case InquiryFormStep.INQUIRY_VARIANT:
                return isInquiryVariantValid;

            case InquiryFormStep.INQUIRY_SUMMARY:
                return isInquirySummaryStepValid;

            default:
                throw new Error(`Invalid form step: ${insuranceFormStep}`)
        }
    }

    const  manageWizardHeadNo = (): void => {
        const isCurrentStepValid: boolean = isStepValid(currentStep);
        const currentStepIndex: number = getStepIndex(currentStep);

        if (currentStepIndex > wizardHeadStepIndex || !isCurrentStepValid) {
            dispatch(changeWizardHeadStepIndex(currentStepIndex));
        }
    }

    const generateUrlFromStep = (step: InquiryFormStep) => {
        const path: string[] = [''];
        path.push(activeLanguage as string);
        path.push('inquiry');
        path.push(step);

        // add inquiryId if exists
        if (inquiryId) {
            path.push(inquiryId);
        }

        return path.join('/');
    }

    useEffect(() => {
        setSteps(getWizardSteps());
        manageWizardHeadNo();
    }, [currentStep]);

    useEffect(() => {
        let url = generateUrlFromStep(currentStep),
            params = new URLSearchParams(window.location.search);

        if (params && params.size > 0) {
            const insuranceId = params.get('insuranceId'),
                secret = params.get('secret');

            const isAdmin = params.get('isAdmin'),
                adminAuthToken = params.get('authToken');

            if (isAdmin === 'true') {
                dispatch(resetToInitialInsuranceState());
                dispatch(setIsAdminPanel(true));
                dispatch(setAdminAuthToken(adminAuthToken));
            } else {
                dispatch(setIsAdminPanel(false));
                dispatch(setAdminAuthToken(null));
            }

            if (insuranceId && secret) {
                dispatch(resetToInitialInsuranceState());
                dispatch(getInquiry(insuranceId, secret));
            } else {
                const firstName = params.get('firstName'),
                    lastName = params.get('lastName'),
                    phone = params.get('phone'),
                    country = params.get('country'),
                    birthDate = params.get('birthDate'),
                    email = params.get('email');

                const inquirySubject: IInquirySubject = {
                    firstName: firstName,
                    lastName: lastName,
                    birthDate: birthDate,
                    address: null,
                    postCode: null,
                    city: null,
                    email: email,
                    phone: phone,
                };

            const inquiryData: IInquiryData = {
                from: null,
                to: null,
                residencyCountry: country,
                studyCountry: null,
                school: null,
                tripReason: null,
            };

                dispatch(changeInquirySubject(inquirySubject));
                dispatch(changeInquiryData(inquiryData));
            }
        }

        history.replace(url);
    }, []);

    useEffect(() => {
        let url = generateUrlFromStep(currentStep);
        history.replace(url)

    }, [currentStep, activeLanguage, inquiryId, history]);

    const getWizardSteps = (): IWizardStep[] => {
        const stepsObj: IWizardStep[] = [];
        formSteps.forEach((stepName: InquiryFormStep, i: number) => {

            const stepIndex = getStepIndex(stepName);

            // step enabled max to headStep and only if previous step is valid
            const enabled = (i <= wizardHeadStepIndex) && (stepsObj.length ? stepsObj[stepsObj.length - 1].isEnabled : true);

            // step is enabled and valid
            const valid = isStepValid(stepName);

            stepsObj.push({
                name: stepName,
                isActive: stepName === currentStep,
                isEnabled: enabled,
                isValid: valid,
                isHead: wizardHeadStepIndex === stepIndex,
            });
        });

        return stepsObj;
    }

    const goToStep = (e: React.MouseEvent<HTMLSpanElement> | null, step: IWizardStep) => {
        if (e) {
            e.preventDefault();
        }

        if (!step.isEnabled) {
            return;
        }

        dispatch(changeCurrentStep(step.name));
        generateUrlFromStep(step.name);
    };

    const renderWizardSteps = () => {
        const wizardSteps: React.ReactElement[] = [];
        steps.forEach((step: IWizardStep, i: number) => {
            const active = step.isActive ? "active" : '',
                enabled = step.isEnabled ? "enabled" : '',
                valid = step.isValid ? "valid" : '',
                head = step.isHead ? "head" : '';

            wizardSteps.push(
                <li className={`${"wizard-step"} ${active} ${enabled} ${valid} ${head}`} key={i}>
                    <span className={`${"label"}`} onClick={e => goToStep(e, step)}>
                        <Translation text={`inquiry.wizardSteps.${step.name}`}/>
                    </span>
                </li>
            )
        });

        return (<ul className={"wizard-steps-container"}>{ wizardSteps }</ul>);
    }

    return (
        <nav className={"navigation-menu"}>

            { renderWizardSteps() }

        </nav>
    )
}

export default StepWizard;
