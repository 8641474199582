import {
    residencyCountryLabelsList,
    destinationCountryLabelsList,
    FormControlType,
    IFormConfig,
    InputType,
    MultiSelectType,
    collectionInputDataMapper,
    collectionOutputDataMapper,
    ValidationRules
} from 'educat-common-web';
import {IInquiryDataFormValue} from "./index";
import moment from "moment";
import {TripReason} from "../../../model/inquiry";


export enum InsuranceDuration {
    OneMonth = '1',
    TwoMonths = '2',
    ThreeMonths = '3',
    FourMonths = '4',
    FiveMonths = '5',
    SixMonths = '6',
}

const getDurationTranslationLabel = (value: InsuranceDuration) => `inquiry.inquiryData.formControls.insuranceDuration.${value}`;
const getStatusTranslationLabel = (value: TripReason) => `inquiry.inquiryData.formControls.tripReasons.${value}`;

export const inquiryDataFormConfig: typeof IFormConfig = (value: any, handleMultiselectInputChange?: any) => {
    const validationRules: any[] = [
        {name: ValidationRules.IS_REQUIRED},
    ];

    return {
        class: 'insurance-data-form',
        dataAccessor: (data: any) => data, // data is not grouped
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);

            return previousStateSnapshot;
        },
        controls: [
            {
                controlType: 'group',
                key: 'insuranceDate',
                class: 'row',
                controls: {
                    insuranceStartDate: {
                        controlType: 'control',
                        additionalStyles: '',
                        formControlType: FormControlType.DATE,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED},
                            {
                                name: ValidationRules.IS_DATE_GTE_THAN,
                                params: {
                                    valueAccessor: (data: any) => data,
                                    compareAccessor: () => moment().subtract(1,'days'),
                                }
                            }
                        ],
                        placeholder: 'inquiry.inquiryData.formControls.placeholders.insuranceDate',
                        isLabelHidden: false,
                        label: 'inquiry.inquiryData.formControls.labels.insuranceDate',
                        type: InputType.DATE,
                        dateFormat: 'yyyy-MM-dd',
                        minDate: moment().add(1,'day'),
                        maxDate: moment().add(1,'year'),
                        hostClass: 'col-xl-12 form-row required',
                        showMonthDropdown: true,
                        showYearDropdown: true,
                    },
                },
            },
            {
                controlType: 'group',
                key: 'insurance_duration',
                class: 'row',
                controls: {
                    insuranceDuration: {
                        hostClass: 'col-xl-12 form-row required duration-radio-control',
                        controlType: 'control',
                        formControlType: FormControlType.RADIO,
                        options: [
                            {value: InsuranceDuration.OneMonth, displayValue: getDurationTranslationLabel(InsuranceDuration.OneMonth)},
                            {value: InsuranceDuration.TwoMonths, displayValue: getDurationTranslationLabel(InsuranceDuration.TwoMonths)},
                            {value: InsuranceDuration.ThreeMonths, displayValue: getDurationTranslationLabel(InsuranceDuration.ThreeMonths)},
                            {value: InsuranceDuration.FourMonths, displayValue: getDurationTranslationLabel(InsuranceDuration.FourMonths)},
                            {value: InsuranceDuration.FiveMonths, displayValue: getDurationTranslationLabel(InsuranceDuration.FiveMonths)},
                            {value: InsuranceDuration.SixMonths, displayValue: getDurationTranslationLabel(InsuranceDuration.SixMonths)},
                        ],
                        validationRules: validationRules,
                        placeholder: 'inquiry.inquiryData.formControls.placeholders.insuranceDuration',
                        label: 'inquiry.inquiryData.formControls.labels.insuranceDuration',
                        type: InputType.RADIO,
                        isLabelTranslated: true,
                    }
                }
            },
            {
                controlType: 'group',
                key: 'residency-country',
                class: 'row',
                controls: {
                    residencyCountry: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: residencyCountryLabelsList || [],
                        validationRules: validationRules,
                        placeholder: 'inquiry.inquiryData.formControls.placeholders.residencyCountry',
                        label: 'inquiry.inquiryData.formControls.labels.residencyCountry',
                        hostClass: 'col-xl-12 form-row required',
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                        isCountryListTranslated: true,
                    },
                }
            },
            {
                controlType: 'group',
                key: 'study-country',
                class: 'row',
                controls: {
                    studyCountry: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        multiselectOptions: destinationCountryLabelsList || [],
                        validationRules: validationRules,
                        placeholder: 'inquiry.inquiryData.formControls.placeholders.studyCountry',
                        label: 'inquiry.inquiryData.formControls.labels.studyCountry',
                        hostClass: 'col-xl-12 form-row required',
                        inputDataMapper: collectionInputDataMapper,
                        outputDataMapper: collectionOutputDataMapper,
                        isCountryListTranslated: true,
                    },
                }
            },
            {
                controlType: 'group',
                key: 'school',
                class: 'row',
                controls: {
                    school: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: validationRules,
                        placeholder: 'inquiry.inquiryData.formControls.placeholders.schoolName',
                        label: 'inquiry.inquiryData.formControls.labels.schoolName',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-row required',
                    },
                }
            },
            {
                controlType: 'group',
                key: 'study_level',
                class: 'row',
                controls: {
                    tripReason: {
                        hostClass: 'col-xl-12 form-row required',
                        controlType: 'control',
                        formControlType: FormControlType.RADIO,
                        options: [
                            {value: TripReason.BACHELOR_DEGREE_STUDIES, displayValue: getStatusTranslationLabel(TripReason.BACHELOR_DEGREE_STUDIES)},
                            {value: TripReason.ENGINEERING_STUDIES, displayValue: getStatusTranslationLabel(TripReason.ENGINEERING_STUDIES)},
                            {value: TripReason.MASTER_DEGREE_STUDIES, displayValue: getStatusTranslationLabel(TripReason.MASTER_DEGREE_STUDIES)},
                            {value: TripReason.DOCTORAL_STUDIES, displayValue: getStatusTranslationLabel(TripReason.DOCTORAL_STUDIES)},
                            {value: TripReason.MBA_STUDIES, displayValue: getStatusTranslationLabel(TripReason.MBA_STUDIES)},
                            {value: TripReason.HIGH_SCHOOL_ABROAD, displayValue: getStatusTranslationLabel(TripReason.HIGH_SCHOOL_ABROAD)},
                            {value: TripReason.ERASMUS_OR_EXCHANGE, displayValue: getStatusTranslationLabel(TripReason.ERASMUS_OR_EXCHANGE)},
                            {value: TripReason.OTHER, displayValue: getStatusTranslationLabel(TripReason.OTHER)}
                        ],
                        validationRules: validationRules,
                        placeholder: '',
                        label: 'inquiry.inquiryData.formControls.labels.tripReason',
                        type: InputType.RADIO,
                        isLabelTranslated: true,
                    }
                }
            },
            {
                controlType: 'group',
                key: 'profile_update_submit',
                class: 'row',
                controls: {
                    submitButton: {
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: 'submit',
                        inputStyles: 'btn btn-theme',
                        defaultContainerStyles: '',
                        containerStyles: 'button-container single-button-container',
                        defaultValue: null,
                        btnText: 'buttons.next',
                        buttonDisabled: (mappedOutputValue: IInquiryDataFormValue, isFormValid: boolean) => {
                            if (mappedOutputValue.insuranceStartDate && mappedOutputValue.insuranceDuration &&
                                mappedOutputValue.residencyCountry && mappedOutputValue.studyCountry &&
                                mappedOutputValue.school && mappedOutputValue.tripReason) {
                                return false;
                            }

                            return !isFormValid;
                        }
                    },
                },
            },
        ]
    };
};
