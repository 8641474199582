import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IInquiryInput, IInquiryOutput, TripReason} from "../../model/inquiry";
import {deepCloneObject} from "educat-common-web";


export enum InquiryFormStep {
    INQUIRY_DATA = 'data',
    INQUIRY_SUBJECT = 'subject',
    INQUIRY_VARIANT = 'variant',
    INQUIRY_SUMMARY = 'summary',
}

export enum ConsentType {
    privacyPolicy = 'privacyPolicy',
    insuranceDocs = 'insuranceDocs',
    requirementsAndNeeds = 'requirementsAndNeeds',
    termsAndConditions = 'termsAndConditions',
    emailMarketing = 'emailMarketing',
    phoneMarketing = 'phoneMarketing',
}

export const inquiryFormSteps = [
    InquiryFormStep.INQUIRY_DATA,
    InquiryFormStep.INQUIRY_SUBJECT,
    InquiryFormStep.INQUIRY_VARIANT,
    InquiryFormStep.INQUIRY_SUMMARY,
];

export interface IInquiryData {
    from: string | null;
    to: string | null;
    residencyCountry: string | null;
    studyCountry: string | null;
    school: string | null;
    tripReason: TripReason | null;
}

export interface IInquirySubject {
    firstName: string | null;
    lastName: string | null;
    birthDate: string | null;
    address: string | null;
    postCode: string | null;
    city: string | null;
    email: string | null;
    phone: string | null;
}

export interface ISelectedInquiryVariant {
    id: string;
    alcoholClause: boolean;
    physicalWork: boolean;
    isTermsAgreement: boolean;
}


export interface IConsentInput {
    type: string,
    didConsent: boolean,
    consentStatement: string,
}

export interface IInquiryConsents {
    privacyPolicy : string | null,
    insuranceDocs: string | null,
    requirementsAndNeeds: string | null,
    termsAndConditions: string | null,
    emailMarketing: string | null,
    phoneMarketing: string | null,
}

export interface IAxaInsuranceState {
    initialized: boolean,
    formSteps: InquiryFormStep[],
    inquiryData: IInquiryData | null;
    inquirySubject: IInquirySubject | null;
    inquiryVariant: ISelectedInquiryVariant | null,
    consents: IInquiryConsents | null;
    inquiryId: string | null;
    inquiry: IInquiryOutput | null;
    currentStep: InquiryFormStep;
    wizardHeadStepIndex: number;
    isLoading: boolean;
    isAdminPanel: boolean;
    adminAuthToken: string | null;
}

export interface IChangeFormSteps {
    formSteps: InquiryFormStep[];
}

export interface IChangeCurrentStep {
    currentStep: InquiryFormStep;
}

export interface IChangeInquiryId {
    inquiryId: string;
}

export interface IChangeInquiryData {
    inquiryData: IInquiryData | null;
}

export interface IChangeInquirySubjectData {
    inquirySubject: IInquirySubject | null;
}

export interface IChangeIsLoading {
    isLoading: boolean;
}

export interface IChangeWizardHeadStepIndex {
    wizardHeadStepIndex: number;
}

export interface IChangeInquiry {
    inquiry: IInquiryOutput | null;
}

export interface IChangeInquiryVariant {
    inquiryVariant: ISelectedInquiryVariant | null;
}

export interface IChangeConsents {
    consents: IInquiryConsents | null;
}

export interface IUpdateInquiry {
    inquiryId: string;
    inquiry: IInquiryInput;
}

export interface IGetInquiry {
    inquiryId: string;
    secret: string;
}

export interface ISetIsAdminPanel {
    isAdmin: boolean;
}

export interface ISetAdminAuthToken {
    authToken: string | null;
}

const initialState: IAxaInsuranceState = {
    initialized: false,
    isLoading: false,
    formSteps: inquiryFormSteps,
    currentStep: InquiryFormStep.INQUIRY_DATA,
    wizardHeadStepIndex: 0,

    inquiryData: null,
    inquirySubject: null,
    inquiryVariant: null,
    consents: null,

    inquiry: null,
    inquiryId: null,

    isAdminPanel: false,
    adminAuthToken: null,
};

const axaInsuranceSlice = createSlice({
    name: "axaInsurance",
    initialState: initialState,
    reducers: {
        changeFormSteps: {
            reducer: (state: IAxaInsuranceState, action: PayloadAction<IChangeFormSteps>) => {
                return {
                    ...state,
                    formSteps: action.payload.formSteps,
                };
            },
            prepare(formSteps: InquiryFormStep[]) {
                return {
                    payload: { formSteps: formSteps }
                };
            }
        },
        changeInquiryData: {
            reducer: (state: IAxaInsuranceState, action: PayloadAction<IChangeInquiryData>) => {
                return {
                    ...state,
                    inquiryData: action.payload.inquiryData
                }
            },
            prepare(inquiryData: IInquiryData) {
                return {
                    payload: {inquiryData}
                }
            }
        },
        changeInquirySubject: {
            reducer: (state: IAxaInsuranceState, action: PayloadAction<IChangeInquirySubjectData>) => {
                    return {
                        ...state,
                        inquirySubject: action.payload.inquirySubject
                    }
            },
            prepare(inquirySubject: IInquirySubject) {
                return {
                    payload: {inquirySubject}
                }
            }
        },
        changeInquiryVariant: {
            reducer: (state: IAxaInsuranceState, action: PayloadAction<IChangeInquiryVariant>) => {
                return {
                    ...state,
                    inquiryVariant: action.payload.inquiryVariant
                }
            },
            prepare(inquiryVariant: ISelectedInquiryVariant | null) {
                return {
                    payload: { inquiryVariant }
                }
            }
        },
        changeConsents: {
            reducer: (state: IAxaInsuranceState, action: PayloadAction<IChangeConsents>) => {
                return {
                    ...state,
                    consents: action.payload.consents
                }
            },
            prepare(consents: IInquiryConsents | null) {
                return {
                    payload: { consents }
                }
            }
        },
        changeCurrentStep: {
            reducer: (state: IAxaInsuranceState, action: PayloadAction<IChangeCurrentStep>) => {
                return {
                    ...state,
                    currentStep: action.payload.currentStep,
                };
            },
            prepare(currentStep: InquiryFormStep) {
                return {
                    payload: { currentStep: currentStep }
                };
            }
        },
        changeInquiryId: {
            reducer: (state: IAxaInsuranceState, action: PayloadAction<IChangeInquiryId>) => {
                return {
                    ...state,
                    inquiryId: action.payload.inquiryId,
                };
            },
            prepare(inquiryId: string) {
                return {
                    payload: {inquiryId: inquiryId}
                };
            }
        },
        changeInquiry: {
            reducer: (state: IAxaInsuranceState, action: PayloadAction<IChangeInquiry>) => {
                return {
                    ...state,
                    inquiry: action.payload.inquiry,
                };
            },
            prepare(inquiry: IInquiryOutput | null) {
                return {
                    payload: {inquiry: inquiry}
                };
            }
        },
        changeWizardHeadStepIndex: {
            reducer: (state: IAxaInsuranceState, action: PayloadAction<IChangeWizardHeadStepIndex>) => {
                return {
                    ...state,
                    wizardHeadStepIndex: action.payload.wizardHeadStepIndex,
                };
            },
            prepare(wizardHeadStepNo: number) {
                return {
                    payload: {wizardHeadStepIndex: wizardHeadStepNo}
                };
            }
        },
        changeFormInitialized: (state: IAxaInsuranceState) => {
            return {
                ...state,
                initialized: true,
            };
        },
        changeIsLoading: {
            reducer: (state: IAxaInsuranceState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: { isLoading }
                };
            }
        },
        addInquiry: {
            reducer: (state: IAxaInsuranceState, action:PayloadAction<any>) => {
               return {
                   ...state,
                   isLoading: true,
               };
            },
            prepare() {
                return { payload: {} }
            }
        },
        updateInquiry: {
            reducer: (state: IAxaInsuranceState, action:PayloadAction<any>) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare()  {
                return { payload: {} }
            }
        },
        checkInquiryUpdateStatus: {
            reducer: (state: IAxaInsuranceState, action:PayloadAction<any>) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare()  {
                return { payload: {} }
            }
        },
        generatePaymentLink: {
            reducer: (state: IAxaInsuranceState, action:PayloadAction<any>) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare() {
                return { payload: {} }
            }
        },
        getInquiry: {
            reducer: (state: IAxaInsuranceState, action:PayloadAction<any>) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(inquiryId: string, secret: string) {
                return {
                    payload: {
                        inquiryId: inquiryId,
                        secret: secret
                    }
                }
            }
        },
        setIsAdminPanel: {
            reducer: (state: IAxaInsuranceState, action:PayloadAction<ISetIsAdminPanel>) => {
                return {
                    ...state,
                    isAdminPanel: action.payload.isAdmin,
                };
            },
            prepare(isAdmin: boolean) {
                return {
                    payload: {
                        isAdmin: isAdmin
                    }
                }
            }
        },
        setAdminAuthToken: {
            reducer: (state: IAxaInsuranceState, action:PayloadAction<ISetAdminAuthToken>) => {
                return {
                    ...state,
                    adminAuthToken: action.payload.authToken,
                };
            },
            prepare(authToken: string | null) {
                return {
                    payload: {
                        authToken: authToken
                    }
                }
            }
        },
        generateInquiryEmail: {
            reducer: (state: IAxaInsuranceState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
                prepare() {
                return {
                    payload: {}
                }
            }
        },
        resetFormAndInitialize: () => {
            const updatedState = Object.assign({}, initialState);
            updatedState.initialized = true;

            return updatedState;
        },
        resetToInitialInsuranceState: () => {
            return deepCloneObject(initialState);
        }
    }
});

export const {
    changeFormSteps,
    changeCurrentStep,
    changeInquiryId,
    changeInquiry,
    changeWizardHeadStepIndex,
    changeIsLoading,
    changeFormInitialized,
    resetFormAndInitialize,
    changeInquiryData,
    changeInquirySubject,
    changeConsents,
    addInquiry,
    changeInquiryVariant,
    generatePaymentLink,
    updateInquiry,
    checkInquiryUpdateStatus,
    setIsAdminPanel,
    setAdminAuthToken,
    generateInquiryEmail,
    getInquiry,
    resetToInitialInsuranceState,
} = axaInsuranceSlice.actions;

export default axaInsuranceSlice.reducer;
