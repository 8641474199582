import React from 'react';
import styles from './styles.module.scss';
import {IPrice} from "../../InquiryVariantStep/variantsStructureData";

interface IPriceProps {
    priceObj: IPrice | null;
    dec: number;
    class?: string;
}

const Price: React.FC<IPriceProps> = (props: IPriceProps) => {

    const formatStrNumber = (xStr: string) => {
        const x: number = Number.parseInt(xStr);
        const formattedStr: string = (x / 100).toFixed(props.dec)

        let parts = formattedStr.split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(",");
    }

    const renderPrice = () => {
        if (!props.priceObj) {
            return (
                <span className={styles.currency}>
                   -
                </span>
            )
        }

        return (
            <>
                <span className={styles.amount}>
                    { formatStrNumber(props.priceObj.amount) }
                </span>
                <span className={styles.currency}>
                    {props.priceObj.currency.code}
                </span>
            </>
        )
    }


    return (
        <span className={`${styles.price} ${props.class ? styles[props.class] : ''}`}>
            { renderPrice() }
        </span>
    );
};

export default Price;
