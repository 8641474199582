import React, {useEffect} from 'react';
import {Loader, LoaderType, Translation} from "educat-common-web";
import {useDispatch, useSelector} from "react-redux";
import {
    addInquiry,
    changeCurrentStep,
    generateInquiryEmail,
    generatePaymentLink,
    InquiryFormStep,
    updateInquiry
} from "../../../store/reducers/axaInsuranceSlice";
import {
    inquirySelector,
    isAdminPanelSelector,
    isAxaInsuranceLoadingSelector,
    isConsentsValidSelector
} from "../../../store/selectors/axaInsuranceSelectors";
import SummaryData from "./SummaryData";
import PurchaseSummary, {PurchaseSummaryType} from "../shared/PurchaseSummary";
import {IInquiryOutput} from "../../../model/inquiry";
import SummaryConsents from "./SummaryConsents";

interface IInquirySummaryStepProps {}

const InquirySummaryStep: React.FC<IInquirySummaryStepProps> = () => {
    const dispatch = useDispatch(),
        isLoading: boolean = useSelector(isAxaInsuranceLoadingSelector),
        inquiry: IInquiryOutput | null = useSelector(inquirySelector),
        isConsentsValid: boolean = useSelector(isConsentsValidSelector),
        isAdminPanel = useSelector(isAdminPanelSelector);
    
    useEffect(() => {
        window.scrollTo(0, 0);

        if (inquiry) {
            dispatch(updateInquiry());
        } else {
            dispatch(addInquiry());
        }
    }, []);

    const handlePayment = () => {
        if (isAdminPanel) {
            dispatch(generateInquiryEmail());
        } else {
            dispatch(generatePaymentLink())
        }
    }

    return (
        <div className="inquiry-form-card summary-card">
            <h1 className="inquiry-step-title">
                <Translation text={'inquiry.inquirySummary.description'}/>
            </h1>

            <SummaryData/>

            <SummaryConsents />

            <PurchaseSummary type={PurchaseSummaryType.Summary}
                             onPrev={() => dispatch(changeCurrentStep(InquiryFormStep.INQUIRY_VARIANT))}
                             onNext={() => handlePayment()}
                             canNext={isAdminPanel || isConsentsValid} />

            <Loader type={LoaderType.Local} showLoader={isLoading}/>
        </div>
    )
}

export default InquirySummaryStep;
