import React, {useEffect, useState} from 'react';
import styles from '../styles.module.scss';
import {
    IVariantStructurePlanDetails,
    IVariantStructureItem,
    variantsStructureData,
    IPrice
} from "../variantsStructureData";
import {Checkbox, Translation} from "educat-common-web";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    changeInquiryVariant,
    IInquiryData,
    ISelectedInquiryVariant
} from "../../../../store/reducers/axaInsuranceSlice";
import {IInquiryVariant} from "../../../../model/inquiryVariant";
import CustomTooltip from "./CustomTooltip";
import VariantsTableCell from "./VariantsTableCell";
import VariantTile from "../VariantTile";
import Price from "../../shared/Price";
import {getPriceByCountryCode} from "../../priceUtil";
import {inquiryDataSelector} from "../../../../store/selectors/axaInsuranceSelectors";

interface IVariantsTableProps {
    inquiryVariants: IInquiryVariant[];
    selectedInquiryVariant: ISelectedInquiryVariant | null;
    isMobile: boolean | null;
}

const VariantsTable: React.FC<IVariantsTableProps> = (props: IVariantsTableProps) => {
    const {t} = useTranslation(),
        dispatch = useDispatch();

    const inquiryData: IInquiryData | null = useSelector(inquiryDataSelector),
        [expandedRows, setExpandedRows] = useState<number[]>([]),
        [variantsToDisplay, setVariantsToDisplay] = useState<IVariantStructureItem[] | []>([]);

    useEffect(() => {
        updateVariantsToDisplay();
    }, [props.isMobile, props.selectedInquiryVariant]);

    const updateVariantsToDisplay = () => {
        let variants = variantsStructureData;

        if (props.isMobile) {
            const selectedVariant = variantsStructureData.find((variant:IVariantStructureItem) =>
                variant.id === props.selectedInquiryVariant?.id);
            if (selectedVariant) variants = [selectedVariant];
        }

        setVariantsToDisplay(variants);
    }
    const handleRowMainClick = (rowId: number) => {
        const currentIndex = expandedRows.indexOf(rowId);
        const newExpandedRows = [...expandedRows];

        if (currentIndex === -1) {
            newExpandedRows.push(rowId);
        } else {
            newExpandedRows.splice(currentIndex, 1);
        }

        setExpandedRows(newExpandedRows);
    };
    const handleVariantChange = (selectedVariantId: string | null) => {
        if (!selectedVariantId) return;

        const isAlcoholClause: boolean = props.selectedInquiryVariant?.alcoholClause || false;
        const isWorkClause: boolean =  props.selectedInquiryVariant?.physicalWork || false;
        updateInquiryVariant(
            selectedVariantId,
            isAlcoholClause,
            isWorkClause,
        );
    }
    const updateInquiryVariant = (selectedVariantId: string, isAlcoholClause: boolean, isWorkClause: boolean) => {
        if (!selectedVariantId) {
            return;
        }

        const inquiryVariant: ISelectedInquiryVariant = {
            id: selectedVariantId,
            alcoholClause: isAlcoholClause,
            physicalWork: isWorkClause,
            isTermsAgreement: props.selectedInquiryVariant?.isTermsAgreement || false,
        }
        dispatch(changeInquiryVariant(inquiryVariant));
    }

    const getInquiryVariantById = (inquiryId: string): IInquiryVariant | null => {
        const inquiryVariant: IInquiryVariant | undefined =
            props.inquiryVariants.find((variant: IInquiryVariant) => variant.id === inquiryId);
        return inquiryVariant ? inquiryVariant : null;
    }
    const getSelectionClass = (variantId: string) => {
        return props.selectedInquiryVariant?.id === variantId ? styles.selected : '';
    }
    const renderColGroup = () => {
        if (props.isMobile) return;

        return (
            <colgroup>
                <col style={{width:220}} />
                <col style={{width:180}} />
                <col style={{width:180}} />
                <col style={{width:180}} />
                <col style={{width:40}} />
            </colgroup>
        )
    }
    const renderTableHeader = () => {

        return (
            <tr className={`${styles.rowTableHeader} ${styles.mHide}`}>
                <td />
                {variantsToDisplay.map((variant: IVariantStructureItem) => (
                    <td key={variant.id}
                        className={getSelectionClass(variant.id)}
                        onClick={() => handleVariantChange(variant.id)}>

                        <VariantTile inquiryVariant={getInquiryVariantById(variant.id)}/>
                    </td>
                ))}
            </tr>
        )
    }
    const renderTableMainContent = () => {
        if (!variantsToDisplay.length) return;

        const renderTableMainRow = (key: string, index: number) => {

            return (
                <tr data-row-id={index}
                    className={`${styles.rowMainContent} ${expandedRows.includes(index) ? styles.expanded : ''}`}>

                    <td className={styles.cellContent}>
                        <div className={styles.labelTooltipContainer}>
                            <CustomTooltip labelKey={key}
                                          isMobile={props.isMobile}/>
                            <label>
                                <Translation text={`inquiry.inquiryVariant.details.${key}`}/>
                            </label>
                        </div>
                    </td>
                    {variantsToDisplay.map((variant: IVariantStructureItem) => (
                        <td key={variant.id}
                            onClick={() => handleVariantChange(variant.id)}
                            className={`${styles.cellContent} ${getSelectionClass(variant.id)}`}>
                            <VariantsTableCell cellObj={variant.planDetails[index].mainContent[key]}/>
                        </td>
                    ))}
                    <td className={styles.cellExpandButton}>
                        <button className={styles.btnExpand}
                                onClick={() => handleRowMainClick(index)}>
                            <i className={styles.btnExpandIcon}/>
                        </button>
                    </td>
                </tr>
            )
        }

        return variantsToDisplay[0].planDetails.map((details: IVariantStructurePlanDetails, index: number) => (
            <React.Fragment key={index}>
                {Object.entries(details.mainContent).map(([key, value], rowIndex: number) => (
                    <React.Fragment key={rowIndex}>
                        { renderTableMainRow(key, index) }
                        { renderRowSpacer('rowMainContentSpacer')}
                        { renderTableAdditionalInfoRows(details, index) }
                    </React.Fragment>
                ))}
            </React.Fragment>
        ))
    }
    const renderRowSpacer = (type: string) => {
        return (
            <tr className={styles[type]}>
                <td/>
                {variantsToDisplay.map((variant: IVariantStructureItem) => (
                    <td key={variant.id} className={getSelectionClass(variant.id)}
                        onClick={() => handleVariantChange(variant.id)}></td>
                ))}
                <td/>
            </tr>
        )
    }
    const renderTableAdditionalInfoRows = (details:IVariantStructurePlanDetails, index: number) => {
        if (!expandedRows.includes(index)) return;

        const renderTableAdditionalInfoRow = (key: string, index: number, subIndex: number) => {
            return (
                <React.Fragment key={`${index}_${subIndex}`}>
                    <tr className={styles.rowExtraContent}>
                        <td className={styles.cellContent}>
                            <label className={styles.extraContentLabel}>
                                <Translation text={`inquiry.inquiryVariant.details.${key}`}/>
                            </label>
                        </td>
                        {variantsToDisplay.map((variant: IVariantStructureItem) => (
                            <td key={variant.id}
                                className={`${styles.cellContent} ${getSelectionClass(variant.id)}`}
                                onClick={() => handleVariantChange(variant.id)}>
                                <VariantsTableCell cellObj={variant.planDetails[index].additionalContent[key]} />
                            </td>
                        ))}
                        <td />
                    </tr>
                    { renderRowSpacer('rowExtraContentSpacer')}
                </React.Fragment>

            )
        }

        return Object.entries(details.additionalContent).map(([subKey, subValue], subIndex: number) =>
            renderTableAdditionalInfoRow(subKey, index, subIndex))
    }
    const renderTableMainButtonRow = () => {
        return (
            <tr className={styles.rowButtons}>
                <td/>
                {variantsToDisplay.map((variant: IVariantStructureItem) => (
                    <td key={variant.id} className={`${styles.cellButton} ${getSelectionClass(variant.id)}`}>
                        <button className={`btn btn-theme ${styles.btnSelectVariant}`}
                                onClick={() => handleVariantChange(variant.id)}>
                            <Translation text={`buttons.${props.selectedInquiryVariant?.id === variant.id ? 'selected' : 'select'}`}/>
                            <i className={styles.btnIcon}/>
                        </button>
                    </td>
                ))}
            </tr>
        )
    }
    const renderTableClausesHeader = () => {

        return (
            <tr className={styles.rowTableHeader}>
                <td colSpan={props.isMobile ? 2 : 1}>
                    <label className={styles.additionalClausesLabel}>
                        <Translation text={'inquiry.inquiryVariant.additionalClauses.title'}/>
                    </label>
                </td>

                { !props.isMobile && variantsToDisplay.map((variant: IVariantStructureItem) => (
                    <td key={variant.id}
                        className={getSelectionClass(variant.id)}
                        onClick={() => handleVariantChange(variant.id)}>
                        <div className={styles.variant}>
                            <p className={styles.variantName}>
                                {variant.name}
                            </p>
                        </div>
                    </td>
                ))}
            </tr>
        )
    }
    const renderTableClausesContent = () => {
        return (
            <>
                <tr className={styles.rowExtraContent}>
                    <td className={styles.cellContent}>
                        <div className={styles.labelTooltipContainer}>
                            <CustomTooltip labelKey={'alcoholClause'}
                                           isMobile={props.isMobile}/>
                            <label className={styles.extraClauseLabel}>
                                <Translation text={`inquiry.inquiryVariant.additionalClauses.alcoholClause`}/>
                            </label>
                        </div>
                    </td>
                    {variantsToDisplay.map((variant: IVariantStructureItem) => (
                        <td key={variant.id}
                            className={`${styles.cellContent} ${getSelectionClass(variant.id)}`}
                            onClick={() => handleVariantChange(variant.id)}>
                            <Checkbox
                                name={'alcoholClause'}
                                handleChange={(e: any) => {
                                    if (!props.selectedInquiryVariant) return;
                                    updateInquiryVariant(
                                        props.selectedInquiryVariant.id,
                                        e.target.checked,
                                        !!props.selectedInquiryVariant?.physicalWork
                                    );
                                }}
                                checked={variant.id === props.selectedInquiryVariant?.id && props.selectedInquiryVariant?.alcoholClause}
                                label={t('inquiry.inquiryVariant.additionalClauses.addClause')}
                                additionalStyles={'additional-clause-checkbox'}
                            />

                        </td>
                    ))}
                    <td/>
                </tr>
                { renderRowSpacer('rowExtraContentSpacer') }
                <tr className={styles.rowExtraContent}>
                    <td className={styles.cellContent}>
                        <div className={styles.labelTooltipContainer}>
                            <CustomTooltip labelKey={'workClause'}
                                           isMobile={props.isMobile}/>
                            <label className={styles.extraClauseLabel}>
                                <Translation text={`inquiry.inquiryVariant.additionalClauses.workClause`}/>
                            </label>
                        </div>
                    </td>
                    {variantsToDisplay.map((variant: IVariantStructureItem) => (
                        <td key={variant.id}
                            className={`${styles.cellContent} ${getSelectionClass(variant.id)}`}
                            onClick={() => handleVariantChange(variant.id)}>
                            <Checkbox
                                name={'workClause'}
                                handleChange={(e: any) => {
                                    if (!props.selectedInquiryVariant) return;
                                    updateInquiryVariant(
                                        props.selectedInquiryVariant.id,
                                        !!props.selectedInquiryVariant?.alcoholClause,
                                        e.target.checked
                                    );
                                }}
                                checked={variant.id === props.selectedInquiryVariant?.id && props.selectedInquiryVariant?.physicalWork}
                                label={t('inquiry.inquiryVariant.additionalClauses.addClause')}
                                additionalStyles={'additional-clause-checkbox'}/>
                        </td>
                    ))}
                    <td/>
                </tr>
            </>
        )
    }
    const renderTableClausesSummary = () => {

        const getAdditionalClausePrice = (variant: IVariantStructureItem): IPrice | null => {
            const variantInquiry: IInquiryVariant | null = getInquiryVariantById(variant.id);

            if (!variantInquiry) return null;

            const variantPriceObj: IPrice | null = getPriceByCountryCode(variantInquiry, inquiryData);

            if (!variantPriceObj) return null;

            let total: number = 0;

            const variantPrice: number = Number.parseInt(variantPriceObj.amount);

            if (props.selectedInquiryVariant?.alcoholClause) total = variantPrice * variantInquiry.alcoholClauseValue;
            if (props.selectedInquiryVariant?.physicalWork) total += variantPrice * variantInquiry.physicalWorkValue;

            return {
                amount: total.toFixed(2),
                currency: { code: variantPriceObj.currency.code }
            };
        }

        return (
            <tr className={styles.rowSummary}>
                <td />
                {variantsToDisplay.map((variant: IVariantStructureItem)=> (
                    <td key={variant.id}
                        className={`${styles.cellContent} ${getSelectionClass(variant.id)}`}
                        onClick={() => handleVariantChange(variant.id)}>

                        { (props.selectedInquiryVariant?.alcoholClause || props.selectedInquiryVariant?.physicalWork) &&
                            (<p className={styles.cellSummary}>
                                <span className={styles.priceContainer}>
                                    + <Price priceObj={getAdditionalClausePrice(variant)} dec={2} />
                                </span>
                                <span className={styles.variantDuration}>
                                    <Translation text={'inquiry.inquiryVariant.coverageDuration'}/>
                                </span>

                            </p>)
                        }
                    </td>
                ))}

                <td/>
            </tr>
        )
    }

    return (
        <table className={styles.variantsTable}>
            { renderColGroup() }
            <tbody>
            { !props.isMobile && renderTableHeader() }
            { renderTableMainContent() }
            { !props.isMobile && renderTableMainButtonRow() }
            { !props.isMobile && renderRowSpacer('rowTableSpacer') }
            { renderTableClausesHeader() }
            { renderTableClausesContent() }
            { !props.isMobile && renderTableClausesSummary() }
            </tbody>
        </table>
    );
};

export default VariantsTable;
