import React, {useEffect} from 'react';
import {activeLanguageSelector, Translation} from "educat-common-web";
import StepWizard from "./FormWizard";
import {
    changeInquiryData,
    checkInquiryUpdateStatus, IInquiryData,
    InquiryFormStep,
    resetToInitialInsuranceState
} from "../../store/reducers/axaInsuranceSlice";
import {useDispatch, useSelector} from "react-redux";
import InquiryDataStep from "./InquiryDataStep";
import {Switch} from "react-router-dom";
import {Route} from "react-router";
import InquirySubjectStep from "./InquirySubjectStep";
import InquiryVariantStep from "./InquiryVariantStep";
import InquirySummaryStep from "./InquirySummaryStep";
import {inquiryDataSelector, inquirySelector} from "../../store/selectors/axaInsuranceSelectors";
import {IInquiryOutput} from "../../model/inquiry";
import moment from 'moment';
import {getInquiryDurationFromInquiryData} from "./inquiryUtil";

interface InquiryFormHostProps {}

const InquiryFormHost: React.FC<InquiryFormHostProps> = () => {
        const dispatch = useDispatch(),
        lng = useSelector(activeLanguageSelector),
        inquiry: IInquiryOutput | null = useSelector(inquirySelector),
        inquiryData: IInquiryData | null = useSelector(inquiryDataSelector);

    useEffect(() => {
        if (inquiry) {
            dispatch(checkInquiryUpdateStatus());
        }
    }, []);

    useEffect(() => {
        if (inquiry && !!(inquiry.acceptedOffer?.paidAt)) {
            dispatch(resetToInitialInsuranceState());
        }
    }, [inquiry]);

    useEffect(() => {

        if (inquiryData) {
            let from = inquiryData.from ? moment(inquiryData.from) : null;
            let to = inquiryData.to ? moment(inquiryData.to) : null;

            if (from && from < moment().endOf('day')) {

                from = moment().utcOffset(0);
                from.set({hour:0,minute:0,second:0,millisecond:0});
                from = moment(from).add(1,'days');

                if (to) {
                    const diff: number | null = getInquiryDurationFromInquiryData(inquiryData);
                    if (diff) to = to.add(diff, 'days');
                }

                const newInquiryData: IInquiryData = {
                    from: from ? from.toISOString() : null,
                    to: to ? to.toISOString() : null,
                    residencyCountry: inquiryData.residencyCountry,
                    studyCountry: inquiryData.studyCountry,
                    school: inquiryData.school,
                    tripReason: inquiryData.tripReason,
                };

                dispatch(changeInquiryData(newInquiryData));
            }
        }
    }, [inquiryData]);

    return (
        <div className="inquiry-form-host">
            <div className="logo-container" />

            <p className='title'><Translation text="inquiry.title"/></p>

            <StepWizard />

            <Switch>
                <Route exact path={`/${lng}/inquiry/${InquiryFormStep.INQUIRY_DATA}`} component={InquiryDataStep}/>
                <Route exact path={`/${lng}/inquiry/${InquiryFormStep.INQUIRY_SUBJECT}`} component={InquirySubjectStep}/>
                <Route exact path={`/${lng}/inquiry/${InquiryFormStep.INQUIRY_VARIANT}`} component={InquiryVariantStep}/>
                <Route exact path={`/${lng}/inquiry/${InquiryFormStep.INQUIRY_SUMMARY}`} component={InquirySummaryStep}/>
            </Switch>
        </div>
    );
};

export default InquiryFormHost;
