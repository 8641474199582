import React, {useState, FC} from 'react';
import {Translation} from "educat-common-web";

interface AccordionItemProps {
    title: string;
    content: string[];
    titleClassName?: string;
    contentClassName?: string;
}

const AccordionItem: FC<AccordionItemProps> = ({ title, content, titleClassName, contentClassName }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={handleClick}>
                <h3 className={titleClassName ? titleClassName : ''}><Translation text={title}/></h3>
                <div className="btn-accordion-expand">
                    <i className={`accordion-icon ${isOpen ? 'expanded' : ''}`}/>
                </div>
            </div>
            {isOpen && <div>
                {content.map((paragraph, index) => (
                    <p key={index} className={contentClassName ? contentClassName : ''}>
                        <Translation text={paragraph} />
                    </p>
                ))}
            </div>}
        </div>
    );
};

export default AccordionItem;
