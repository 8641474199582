import {IPrice} from "educat-common-web";
import {IInquiryVariant} from "./inquiryVariant";

export enum TripReason {
    BACHELOR_DEGREE_STUDIES = 'bachelor_degree_studies',
    ENGINEERING_STUDIES = 'engineering_studies',
    MASTER_DEGREE_STUDIES = 'master_degree_studies',
    DOCTORAL_STUDIES = 'doctoral_studies',
    MBA_STUDIES = 'mba_studies',
    HIGH_SCHOOL_ABROAD = 'high_school_abroad',
    ERASMUS_OR_EXCHANGE = 'erasmus_or_exchange',
    OTHER = 'other',
}

export enum OfferStatus {
    STATUS_PAID = 'status_paid',
    STATUS_ACTIVE = 'status_active',
    STATUS_EXPIRED = 'status_expired',
    STATUS_EXPIRED_DRAFT = 'status_expired_draft',
    STATUS_MODIFIED = 'status_modified',
    STATUS_DRAFT = 'status_draft',
}

export enum PaymentStatus {
    STATUS_NEW = 'status_new',
    STATUS_PAID = 'status_paid',
    STATUS_EXPIRED = 'status_expired',
    STATUS_REQUIRES_PAYMENT_METHOD = 'status_requires_payment_method',
    STATUS_REQUIRES_USER_ACTION = 'status_requires_user_action',
}

export interface IInquirySubjectInput {
    readonly firstName: string;
    readonly lastName: string;
    readonly birthDate: string;
    readonly address: {
        readonly addressLine1: string;
        readonly addressLine2: string | null;
        readonly addressLine3: string | null;
        readonly postcode: string;
        readonly city: string;
        readonly country: string;
    },
    readonly main: boolean;
    readonly email: string;
    readonly phone: string;
}

export interface IInquirySubjectOutput extends IInquirySubjectInput {
    readonly id: string;
    readonly inquiry: string;
}

export interface IInquiryInput {
    readonly from: string;
    readonly to: string;
    readonly timezone: string;
    readonly inquiryVariantId: string;
    readonly tripReason: TripReason;
    readonly schoolName: string;
    readonly alcoholClause: boolean;
    readonly physicalWork: boolean;
    readonly destination: string;
    readonly inquirySubjects: IInquirySubjectInput[];
    readonly reference: string | null;
    readonly guestEmail: string;
    readonly visitDate: string;
    readonly source: string | null;
}

export interface IMediaObject {
    readonly id: string;
    readonly largeThumb: string;
    readonly mediumThumb: string;
    readonly original: string;
    readonly public: boolean;
    readonly smallThumb: string;
    readonly tinyThumb: string;
}

export interface IOffer {
    readonly class: string;
    readonly id: string;
    readonly paidAt: string | null;
    readonly payments: []; // toDo add type
    readonly premium: typeof IPrice;
    readonly reference: string | null;
    readonly status: string;
    readonly systemFee: typeof IPrice;
    readonly axaPremium: typeof IPrice;
    readonly validUntil: string;
}

export interface IInquiryOutput {
    readonly acceptedOffer: any; // can be null;
    readonly alcoholClause: boolean;
    readonly applicant: any; // can be null;
    readonly createdAt: string;
    readonly destination: string;
    readonly from: string;
    readonly googleFeedSynchronizedAt: string | null;
    readonly guestEmail: string;
    readonly id: string;
    readonly inquirySubjects: IInquirySubjectOutput[];
    readonly inquiryVariant: IInquiryVariant;
    readonly insuranceDocs: IMediaObject[];
    readonly mentor: any; // can be null
    readonly offers: IOffer[];
    readonly physicalWork: boolean;
    readonly schoolName: string;
    readonly secret: string;
    readonly settled: boolean;
    readonly source: string | null;
    readonly synchronizedAt: string | null;
    readonly to: string;
    readonly tripReason: TripReason;
    readonly updatedAt: string;
    readonly visitDate: string;
}
