import React, {Fragment} from 'react';
import {Checkbox, Translation} from "educat-common-web";
import {ConsentType} from "../../../../../store/reducers/axaInsuranceSlice";

interface IConsentCheckboxProps {
    statement?: string;
    statementLabel: string;
    class?: string;
    name: ConsentType | 'all';
    isChecked: boolean;
    onChange: (e: boolean) => void;

}

const ConsentCheckbox: React.FC<IConsentCheckboxProps> = (props) => {

    return (
        <label className={`consent${props.class ? ` ${props.class}` : ``}`} htmlFor={props.name}>
            <Checkbox name={props.name}
                      handleChange={(e: any) => props.onChange(e.target.checked)}
                      checked={props.isChecked}/>
            <span className="consent-label" dangerouslySetInnerHTML={{__html: props.statementLabel}} />
        </label>
    )
}

export default ConsentCheckbox;
