export interface IPrice {
    amount: string,
    currency: {
        code: string
    },
}
export interface IVariantStructureCellString {
    label: string,
    class?: string,
}

export interface IVariantStructureCellIcon {
    icon: string,
    class?: string,
}
export interface IVariantStructureCell {
    type: "numeric" | "string" | "icon";
    value: IPrice | IVariantStructureCellString | IVariantStructureCellIcon;
}
export interface IVariantStructurePlanDetails {
    mainContent: {[key: string]: IVariantStructureCell};
    additionalContent: {[key: string]: IVariantStructureCell};
}

export interface IVariantStructureItem {
    id: string;
    name: string;
    planDetails: IVariantStructurePlanDetails[];
}

export const variantsStructureData: IVariantStructureItem[] = [
    {
        id: "59644e11-5efc-4618-9be2-fa521edfe858",
        name: "All inclusive",
        planDetails: [
            {
                mainContent: {
                    treatmentCosts: {
                        type: "numeric",
                        value: {
                            amount: "30000000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
                additionalContent: {
                    dentistCosts: {
                        type: "numeric",
                        value: {
                            amount: "30000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    clinicTransportation: {
                        type: "string",
                        value: {
                            label: "do sumy ubezpieczenia",
                            class: 'small',
                        },
                    },
                    emergencyMedicalServices: {
                        type: "numeric",
                        value: {
                            amount: "1000000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    residencyCountryTransportation: {
                        type: "string",
                        value: {
                            label: "do sumy ubezpieczenia",
                            class: 'small',
                        },
                    },
                    residencyTransportation: {
                        type: "string",
                        value: {
                            label: "do sumy ubezpieczenia",
                            class: 'small',
                        },
                    },
                    extendedStay: {
                        type: "string",
                        value: {
                            label: "<div><span>150</span> EUR</div><div>wyżywienie i nocleg/dobę</div>",
                            class: 'small',
                        },
                    },
                    coffinTransportation: {
                        type: "string",
                        value: {
                            label: "do sumy ubezpieczenia",
                            class: 'small',
                        },
                    },
                    assistantCosts: {
                        type: "string",
                        value: {
                            label: "<div>Transport - do sumy ubezpieczenia</div><div>Nocleg - <span>150</span> EUR/noc</div>",
                            class: 'small',
                        },
                    },
                    assistanceCenter: {
                        type: "icon",
                        value: {
                            icon: 'check'
                        },
                    },
                    baggageDelay: {
                        type: "numeric",
                        value: {
                            amount: "15000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    flightDelay: {
                        type: "numeric",
                        value: {
                            amount: "15000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
            },
            {
                mainContent: {
                    accidents: {
                        type: "numeric",
                        value: {
                            amount: "1000000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
                additionalContent: {
                    permanentHealthDamage: {
                        type: "numeric",
                        value: {
                            amount: "1000000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    death: {
                        type: "numeric",
                        value: {
                            amount: "500000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
            },
            {
                mainContent: {
                    luggage: {
                        type: "numeric",
                        value: {
                            amount: "120000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
                additionalContent: {
                    specialLuggage: {
                        type: "numeric",
                        value: {
                            amount: "60000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
            },
            {
                mainContent: {
                    liability: {
                        type: "numeric",
                        value: {
                            amount: "3000000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
                additionalContent: {
                    personalInjuries: {
                        type: "numeric",
                        value: {
                            amount: "3000000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    propertyDamage: {
                        type: "numeric",
                        value: {
                            amount: "1500000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    damageShare: {
                        type: "string",
                        value: {
                            label: "<span>100</span> %",
                        },
                    },
                },
            },
            {
                mainContent: {
                    studyInterruption: {
                        type: "numeric",
                        value: {
                            amount: "300000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
                additionalContent: {
                    semesterPaymentRefund: {
                        type: "numeric",
                        value: {
                            amount: "300000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
            },
            // {
            //     mainContent: {
            //         inquiryExpansion: {
            //             type: "icon",
            //             value: {
            //                 icon: 'check'
            //             },
            //         },
            //     },
            //     additionalContent: {
            //         alcoholClause:  {
            //             type: "string",
            //             value: {
            //                 label: "+ <span>15</span> %",
            //             },
            //         },
            //         physicalClause:  {
            //             type: "string",
            //             value: {
            //                 label: "+ <span>80</span> %",
            //             },
            //         },
            //         europeAdditionalPrice: {
            //             type: "numeric",
            //             value: {
            //                 amount: "6800",
            //                 currency: {
            //                     code: "EUR"
            //                 },
            //             },
            //         },
            //         worldAdditionalPrice: {
            //             type: "numeric",
            //             value: {
            //                 amount: "8500",
            //                 currency: {
            //                     code: "EUR"
            //                 },
            //             },
            //         },
            //     },
            // }
        ],
    },
    {
        id: "235970e3-6c7a-43f5-bb86-6897bc00b6d1",
        name: "Premium",
        planDetails: [
            {
                mainContent: {
                    treatmentCosts: {
                        type: "numeric",
                        value: {
                            amount: "10000000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
                additionalContent: {
                    dentistCosts: {
                        type: "numeric",
                        value: {
                            amount: "20000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    clinicTransportation: {
                        type: "string",
                        value: {
                            label: "do sumy ubezpieczenia",
                            class: 'small',
                        },
                    },
                    emergencyMedicalServices: {
                        type: "numeric",
                        value: {
                            amount: "1000000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    residencyCountryTransportation: {
                        type: "string",
                        value: {
                            label: "do sumy ubezpieczenia",
                            class: 'small',
                        },
                    },
                    residencyTransportation: {
                        type: "string",
                        value: {
                            label: "do sumy ubezpieczenia",
                            class: 'small',
                        },
                    },
                    extendedStay: {
                        type: "string",
                        value: {
                            label: "<div><span>100</span> EUR</div><div>wyżywienie i nocleg/dobę</div>",
                            class: 'small',
                        },
                    },
                    coffinTransportation: {
                        type: "string",
                        value: {
                            label: "do sumy ubezpieczenia",
                            class: 'small',
                        },
                    },
                    assistantCosts: {
                        type: "string",
                        value: {
                            label: "<div>Transport - do sumy ubezpieczenia</div><div>Nocleg - <span>100</span> EUR/noc</div>",
                            class: 'small',
                        },
                    },
                    assistanceCenter: {
                        type: "icon",
                        value: {
                            icon: 'check'
                        },
                    },
                    baggageDelay: {
                        type: "numeric",
                        value: {
                            amount: "10000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    flightDelay: {
                        type: "numeric",
                        value: {
                            amount: "10000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
            },
            {
                mainContent: {
                    accidents: {
                        type: "numeric",
                        value: {
                            amount: "600000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
                additionalContent: {
                    permanentHealthDamage: {
                        type: "numeric",
                        value: {
                            amount: "600000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    death: {
                        type: "numeric",
                        value: {
                            amount: "300000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
            },
            {
                mainContent: {
                    luggage: {
                        type: "numeric",
                        value: {
                            amount: "65000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
                additionalContent: {
                    specialLuggage: {
                        type: "numeric",
                        value: {
                            amount: "32500",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
            },
            {
                mainContent: {
                    liability: {
                        type: "numeric",
                        value: {
                            amount: "1500000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
                additionalContent: {
                    personalInjuries: {
                        type: "numeric",
                        value: {
                            amount: "1500000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    propertyDamage: {
                        type: "numeric",
                        value: {
                            amount: "750000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    damageShare: {
                        type: "string",
                        value: {
                            label: "<span>100</span> %",
                        },
                    },
                },
            },
            {
                mainContent: {
                    studyInterruption: {
                        type: "numeric",
                        value: {
                            amount: "100000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
                additionalContent: {
                    semesterPaymentRefund: {
                        type: "numeric",
                        value: {
                            amount: "100000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
            },
            // {
            //     mainContent: {
            //         inquiryExpansion: {
            //             type: "icon",
            //             value: {
            //                 icon: 'check'
            //             },
            //         },
            //     },
            //     additionalContent: {
            //         alcoholClause: {
            //             type: "string",
            //             value: {
            //                 label: "+ <span>15</span> %",
            //             },
            //         },
            //         physicalClause: {
            //             type: "string",
            //             value: {
            //                 label: "+ <span>80</span> %",
            //             },
            //         },
            //         europeAdditionalPrice: {
            //             type: "numeric",
            //             value: {
            //                 amount: "4800",
            //                 currency: {
            //                     code: "EUR"
            //                 },
            //             },
            //         },
            //         worldAdditionalPrice: {
            //             type: "numeric",
            //             value: {
            //                 amount: "5500",
            //                 currency: {
            //                     code: "EUR"
            //                 },
            //             },
            //         }
            //     },
            // }
        ],
    },
    {
        id: "0f44f714-26f7-490b-980b-8b907a9f3523",
        name: "Essentials",
        planDetails: [
            {
                mainContent: {
                    treatmentCosts: {
                        type: "numeric",
                        value: {
                            amount: "5000000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
                additionalContent: {
                    dentistCosts: {
                        type: "numeric",
                        value: {
                            amount: "10000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    clinicTransportation: {
                        type: "string",
                        value: {
                            label: "do sumy ubezpieczenia",
                            class: 'small',
                        },
                    },
                    emergencyMedicalServices: {
                        type: "numeric",
                        value: {
                            amount: "1000000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    residencyCountryTransportation: {
                        type: "string",
                        value: {
                            label: "do sumy ubezpieczenia",
                            class: 'small',
                        },
                    },
                    residencyTransportation: {
                        type: "string",
                        value: {
                            label: "do sumy ubezpieczenia",
                            class: 'small',
                        },
                    },
                    extendedStay: {
                        type: "string",
                        value: {
                            label: "<div><span>50</span> EUR</div><div>wyżywienie i nocleg/dobę</div>",
                            class: 'small',
                        },
                    },
                    coffinTransportation: {
                        type: "string",
                        value: {
                            label: "do sumy ubezpieczenia",
                            class: 'small',
                        },
                    },
                    assistantCosts: {
                        type: "string",
                        value: {
                            label: "<div>Transport - do sumy ubezpieczenia</div><div>Nocleg - <span>50</span> EUR/noc</div>",
                            class: 'small',
                        },
                    },
                    assistanceCenter: {
                        type: "icon",
                        value: {
                            icon: 'check'
                        },
                    },
                    baggageDelay: {
                        type: "numeric",
                        value: {
                            amount: "5000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    flightDelay: {
                        type: "numeric",
                        value: {
                            amount: "5000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
            },
            {
                mainContent: {
                    accidents: {
                        type: "numeric",
                        value: {
                            amount: "400000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
                additionalContent: {
                    permanentHealthDamage: {
                        type: "numeric",
                        value: {
                            amount: "400000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                    death: {
                        type: "numeric",
                        value: {
                            amount: "200000",
                            currency: {
                                code: "EUR"
                            },
                        },
                    },
                },
            },
            {
                mainContent: {
                    luggage: {
                        type: "icon",
                        value: {
                            icon: 'x'
                        },
                    },
                },
                additionalContent: {
                    specialLuggage: {
                        type: "icon",
                        value: {
                            icon: 'x'
                        },
                    }
                },
            },
            {
                mainContent: {
                    liability: {
                        type: "icon",
                        value: {
                            icon: 'x'
                        },
                    }
                },
                additionalContent: {
                    personalInjuries: {
                        type: "icon",
                        value: {
                            icon: 'x'
                        },
                    },
                    propertyDamage: {
                        type: "icon",
                        value: {
                            icon: 'x'
                        },
                    },
                    damageShare: {
                        type: "icon",
                        value: {
                            icon: 'x'
                        },
                    },
                },
            },
            {
                mainContent: {
                    studyInterruption: {
                        type: "icon",
                        value: {
                            icon: 'x'
                        },
                    },
                },
                additionalContent: {
                    semesterPaymentRefund: {
                        type: "icon",
                        value: {
                            icon: 'x'
                        },
                    },
                },
            },
            // {
            //     mainContent: {
            //         inquiryExpansion: {
            //             type: "icon",
            //             value: {
            //                 icon: 'check'
            //             },
            //         },
            //     },
            //     additionalContent: {
            //         alcoholClause: {
            //             type: "string",
            //             value: {
            //                 label: "+ <span>15</span> %",
            //             }
            //         },
            //         physicalClause: {
            //             type: "string",
            //             value: {
            //                 label: "+ <span>80</span> %",
            //             },
            //         },
            //         europeAdditionalPrice: {
            //             type: "numeric",
            //             value: {
            //                 amount: "2800",
            //                 currency: {
            //                     code: "EUR"
            //                 },
            //             },
            //         },
            //         worldAdditionalPrice: {
            //             type: "numeric",
            //             value: {
            //                 amount: "3500",
            //                 currency: {
            //                     code: "EUR"
            //                 },
            //             },
            //         }
            //     },
            // }
        ],
    },
]