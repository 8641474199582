import React, {useEffect, useState} from 'react';
import {activeLanguageSelector, Translation} from "educat-common-web";
import {useDispatch, useSelector} from "react-redux";
import {checkInquiryUpdateStatus, InquiryFormStep} from "../../store/reducers/axaInsuranceSlice";
import {IInquiryOutput} from "../../model/inquiry";
import {inquirySelector} from "../../store/selectors/axaInsuranceSelectors";
import {Redirect} from "react-router";

interface ISuccessPageProps {}

const SuccessPage: React.FC<ISuccessPageProps> = () => {
    const dispatch = useDispatch(),
        lng = useSelector(activeLanguageSelector),
        inquiry: IInquiryOutput | null = useSelector(inquirySelector),
        [isLoading, setIsLoading] = useState<boolean>(true),
        [isPaid, setIsPaid] = useState<boolean>(false),
        [doRedirect, setDoRedirect] = useState<boolean>(false),
        [isAdminPanel, setIsAdminPanel] = useState<boolean>(false);

    useEffect(() => {
        if (inquiry) {
            dispatch(checkInquiryUpdateStatus());
        }
        const params = new URLSearchParams(window.location.search);
        if (params && params.size > 0) {
            const isAdmin = params.get('isAdmin');
            setIsAdminPanel(isAdmin === 'true');
        }
    }, []);

    useEffect(() => {
        if (!inquiry) {
            setDoRedirect(true);
        } else {
            setIsLoading(false)
            if (!!inquiry.acceptedOffer?.paidAt) {
                setIsPaid(true)
            }
        }
    }, [inquiry]);

    const redirectToMainPage = () => {
        window.location.href = `${process.env.REACT_APP_HOMEPAGE_URL}`;
    }

    const refreshInquiryStatus = () => {
        dispatch(checkInquiryUpdateStatus());
    }

    const renderCardContent = () => {
        if (isAdminPanel) {
            return (
                <>
                    <h3 className={"success-title"}><Translation text={'successPage.adminPanel.title'}/></h3>
                    <p className={"success-description"}><Translation text={'successPage.adminPanel.description'}/></p>
                </>
            )
        }

        if (isPaid) return (
            <>
                <h3 className={"success-title"}><Translation text={'successPage.paid.title'}/></h3>
                <p className={"success-description"}><Translation text={'successPage.paid.description'}/></p>
                {renderSocialIcons()}
                <button className="btn btn-theme" onClick={() => redirectToMainPage()}>
                    <Translation text="successPage.paid.btnAction" />
                </button>
            </>
        )

        return (
            <>
                <h3 className={"success-title"}><Translation text={'successPage.notPaid.title'}/></h3>
                <p className={"success-description"}><Translation text={'successPage.notPaid.description'}/></p>

                {renderSocialIcons()}

                <button className="btn btn-theme" onClick={() => refreshInquiryStatus()}>
                    <Translation text="successPage.notPaid.btnAction" />
                </button>
            </>
        )
    }

    const renderCard = () => {
        if (isLoading) return;

        return (
            <div className={"inquiry-form-card success-card"}>
                <div className="success-details">

                    { renderCardContent()}

                </div>
            </div>
        )
    }

    const renderSocialIcons = () => {
        return(
            <div className={"social-icons-wrapper"}>
                <a href="https://www.facebook.com/profile.php?id=100063901011756"
                   className={"social-link"}
                   target="_blank"
                   rel="noreferrer">
                    <span className={"icon facebook"}/>
                    <span className="sr-only">
                        <Translation text="footer.socialIcons.facebook" />
                    </span>
                </a>
                <a href="https://www.instagram.com/educat.study/"
                   className={"social-link"}
                   target="_blank"
                   rel="noreferrer">
                    <span className={"icon instagram"}/>
                    <span className="sr-only">
                        <Translation text="footer.socialIcons.instagram" />
                    </span>
                </a>
                <a href="https://www.linkedin.com/company/educat-study/"
                   className={"social-link"}
                   target="_blank"
                   rel="noreferrer">
                    <span className={"icon linkedin"}/>
                    <span className="sr-only">
                        <Translation text="footer.socialIcons.linkedin" />
                    </span>
                </a>
            </div>
        )
    }

    return (
        <div className={"inquiry-form-host"}>

            { doRedirect && <Redirect to={`/${lng}/inquiry/${InquiryFormStep.INQUIRY_DATA}`}/> }

            { renderCard() }

        </div>
    )
}

export default SuccessPage;
