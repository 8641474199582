import React from 'react';
import {BehaviorSubject} from 'rxjs';
import {Form, FormControlChangeType, IFormConfig, Translation} from "educat-common-web";
import {useEffect, useMemo, useState} from "react";
import {filter, tap} from 'rxjs/operators';
import {inquiryDataFormConfig} from "./inquiryDataFormConfig";
import {useDispatch, useSelector} from "react-redux";
import {
    changeCurrentStep,
    changeInquiryData,
    IInquiryData,
    InquiryFormStep
} from "../../../store/reducers/axaInsuranceSlice";
import {inquiryDataSelector} from "../../../store/selectors/axaInsuranceSelectors";
import moment from "moment";
import {TripReason} from "../../../model/inquiry";
import {
    getInquiryDurationStrFromInquiryData,
    getInquiryEndDateISOString,
} from "../inquiryUtil";


export interface IInquiryDataFormValue {
    insuranceStartDate: string | null;
    insuranceDuration: string | null;
    residencyCountry: string | null;
    studyCountry: string | null;
    school: string | null;
    tripReason: TripReason | null;
}
export interface ValueType<T> {
    controlName: string;
    value: T;
    changeType: typeof FormControlChangeType;
}
interface IInquiryDataStepProps {}

const InquiryDataStep: React.FC<IInquiryDataStepProps> = () => {
    const dispatch = useDispatch(),
        onValueStateChange$ = useMemo(() => new BehaviorSubject<ValueType<IInquiryDataFormValue> | null>(null), []),
        inquiryData: IInquiryData | null = useSelector(inquiryDataSelector),
        [formConfig, setFormConfig] = useState<typeof IFormConfig | null>(null);


        const [value, setValue] = useState<IInquiryDataFormValue | null>(inquiryData ? {
            insuranceStartDate: moment(inquiryData?.from).format('YYYY-MM-DD') || null,
            insuranceDuration: getInquiryDurationStrFromInquiryData(inquiryData),
            residencyCountry: inquiryData?.residencyCountry || null,
            studyCountry: inquiryData?.studyCountry || null,
            school: inquiryData?.school || null,
            tripReason: inquiryData?.tripReason || null
        } : null);

    const setForm = (): void => {
        const formConfig = inquiryDataFormConfig(value);
        setFormConfig(formConfig);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setForm();
    }, []);

    useEffect(() => {
        const subscription = onValueStateChange$.pipe(
            filter((data: any) => data && data.changeType === FormControlChangeType.User),
            tap((data: any) => setValue(data.value)),
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        }
    }, [onValueStateChange$]);

    useEffect(() => {
        setValue({
            insuranceStartDate: inquiryData?.from ? moment(inquiryData?.from).format('YYYY-MM-DD') : null,
            insuranceDuration: getInquiryDurationStrFromInquiryData(inquiryData),
            residencyCountry: inquiryData?.residencyCountry || null,
            studyCountry: inquiryData?.studyCountry || null,
            school: inquiryData?.school || null,
            tripReason: inquiryData?.tripReason || null
        })
        setForm();
    }, [inquiryData])

    const onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    const updateData = () => {
        if (!value ||
            !value.insuranceStartDate ||
            !value.insuranceDuration ||
            !value.residencyCountry ||
            !value.studyCountry ||
            !value.school ||
            !value.tripReason) {
            return;
        }

        const from: string = moment(value.insuranceStartDate).toISOString();
        const to: string | null = getInquiryEndDateISOString(value.insuranceStartDate, value.insuranceDuration);

        const inquiryData: IInquiryData = {
            from: from,
            to: to,
            residencyCountry: value.residencyCountry,
            studyCountry: value.studyCountry,
            school: value.school,
            tripReason: value.tripReason,
        };

        dispatch(changeInquiryData(inquiryData));
        dispatch(changeCurrentStep(InquiryFormStep.INQUIRY_SUBJECT));
    }

    return (
        <div className={"inquiry-form-card inquiry-data"}>
        <h1 className="inquiry-step-title"><Translation text={'inquiry.inquiryData.description'}/></h1>

        {formConfig && <Form config={formConfig}
                             onValueStateChange={onValueStateChange}
                             value={value}
                             submitForm={updateData}
                             controlName={'inquiryDataForm'}/>}
        </div>
    )
}

export default InquiryDataStep;
