import {createSelector} from '@reduxjs/toolkit';
import { RootState } from "../reducers";
import {IInquiryVariantListState} from "../reducers/inquiryVariantListSlice";

export const selectInquiryVariants = (state: RootState): IInquiryVariantListState => {
    return state.inquiryVariantsList;
};

export const inquiryVariantsSelector = createSelector(
    [selectInquiryVariants],
    (state: IInquiryVariantListState) => state.inquiryVariants
);

export const isInquiryVariantsListLoadingSelector = createSelector(
    [selectInquiryVariants],
    (state: IInquiryVariantListState) => state.isLoading
);

export const isInquiryVariantsListInitializedSelector = createSelector(
    [selectInquiryVariants],
    (state: IInquiryVariantListState) => state.initialized
);

export const inquiryVariantsListErrorSelector = createSelector(
    [selectInquiryVariants],
    (state: IInquiryVariantListState) => state.error
);
