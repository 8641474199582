import {
    addAlert,
    AlertType,
    ApiError,
    handleApiError,
} from 'educat-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {RootState} from '../reducers';
import {
    changeInquiryVariants,
    changeInquiryVariantsError,
    changeIsLoading,
    fetchInquiryVariants
} from "../reducers/inquiryVariantListSlice";
import {getInquiryVariantsAPI} from "../../api/inquiryVariant/getInquiryVariantsAPI";

const fetchInquiryVariantsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchInquiryVariants.type),
        switchMap((): any => {
            return getInquiryVariantsAPI().pipe(
                switchMap((resp: any) => {

                    const inquiryVariants = resp['hydra:member'],
                        actions = [changeInquiryVariants(inquiryVariants), changeIsLoading(false)];
                    return of(...actions);
                }),
                catchError((error: any) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );};

const errorActions = (error: typeof ApiError) => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [addAlert(errorObj), changeIsLoading(false), changeInquiryVariantsError(errorObj.message)];
};

const inquiryVariantsListEpic = combineEpics(fetchInquiryVariantsEpic);

export default inquiryVariantsListEpic;