import { combineReducers } from "redux";
import {connectRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import {alertSlice, sagaSlice} from "educat-common-web";
import axaInsuranceSlice from "./axaInsuranceSlice";
import inquiryVariantListSlice from "./inquiryVariantListSlice";


const history = createBrowserHistory();

const appReducer = combineReducers({
    alert: alertSlice,
    saga: sagaSlice,
    axaInsurance: axaInsuranceSlice,
    inquiryVariantsList: inquiryVariantListSlice,
    router: connectRouter(history)
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
