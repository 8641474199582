import {educatAPI, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';
import {ISetAcceptedOfferPayload} from "../../store/epics/axaInsuranceEpic";
export function setAcceptedOfferAPI(inquiryId: string, payload: ISetAcceptedOfferPayload): Observable<any> {
    return educatAPI.put(
        `inquiry/${inquiryId}/set_accepted_offer`,
        payload,
        new RestQueryParams(),
        {}
    );
}