import {
    FormControlType,
    IFormConfig,
    InputType,
    residencyCountryPhonePrefixesList,
    ValidationRules,
} from 'educat-common-web';
import moment from "moment";
import {IInquirySubjectFormValue} from "./index";

export const inquirySubjectFormConfig: typeof IFormConfig = (value: any, handlePrevClick: () => void) => {
    return {
        controlType: 'form',
        class: 'insurance-data-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'subject_name',
                controlType: 'group',
                class: 'row multiple-controls-row',
                controls: {
                    firstName: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED},
                        ],
                        placeholder: 'inquiry.inquirySubject.formControls.placeholders.firstName',
                        isLabelHidden: false,
                        label: 'inquiry.inquirySubject.formControls.labels.firstName',
                        type: InputType.TEXT,
                        hostClass: 'form-control required',
                    },
                    lastName: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED},
                        ],
                        placeholder: 'inquiry.inquirySubject.formControls.placeholders.lastName',
                        isLabelHidden: false,
                        label: 'inquiry.inquirySubject.formControls.labels.lastName',
                        type: InputType.TEXT,
                        hostClass: 'form-control required',
                    }
                }
            },
            {
                key: 'subject_birthdate',
                controlType: 'group',
                class: 'row multiple-controls-row',
                controls: {
                    birthDate: {
                        controlType: 'control',
                        additionalStyles: '',
                        formControlType: FormControlType.DATE,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED},
                        ],
                        placeholder: 'inquiry.inquirySubject.formControls.placeholders.birthDate',
                        isLabelHidden: false,
                        label: 'inquiry.inquirySubject.formControls.labels.birthDate',
                        type: InputType.DATE,
                        dateFormat: 'yyyy-MM-dd',
                        minDate: moment().subtract(31,'years').add(1, 'days').format('YYYY-MM-DD'),
                        maxDate: moment().format('YYYY-MM-DD'),
                        openToDate: moment('01/01/2000').format('YYYY-MM-DD'),
                        hostClass: 'form-control required',
                        showMonthDropdown: true,
                        showYearDropdown: true,
                    },
                    address: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED},
                        ],
                        placeholder: 'inquiry.inquirySubject.formControls.placeholders.address',
                        isLabelHidden: false,
                        label: 'inquiry.inquirySubject.formControls.labels.address',
                        type: InputType.TEXT,
                        hostClass: 'form-control required',
                    }
                }
            },
            {
                key: 'subject_address',
                controlType: 'group',
                class: 'row multiple-controls-row',
                controls: {
                    postCode: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED},
                        ],
                        placeholder: 'inquiry.inquirySubject.formControls.placeholders.postCode',
                        isLabelHidden: false,
                        label: 'inquiry.inquirySubject.formControls.labels.postCode',
                        type: InputType.TEXT,
                        hostClass: 'form-control required',
                    },
                    city: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED},
                        ],
                        placeholder: 'inquiry.inquirySubject.formControls.placeholders.city',
                        isLabelHidden: false,
                        label: 'inquiry.inquirySubject.formControls.labels.city',
                        type: InputType.TEXT,
                        hostClass: 'form-control required',
                    }
                }
            },
            {
                key: 'subject_email',
                controlType: 'group',
                class: 'row multiple-controls-row',
                controls: {
                    email: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED, params: {}},
                            {name: ValidationRules.IS_EMAIL, params: {}}
                        ],
                        placeholder: 'inquiry.inquirySubject.formControls.placeholders.email',
                        isLabelHidden: false,
                        label: 'inquiry.inquirySubject.formControls.labels.email',
                        type: InputType.EMAIL,
                        hostClass: 'col-xl-12 form-control required'
                    },
                    phone: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.PHONE_NUMBER_INPUT,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED, params: {}},
                            {name: ValidationRules.MIN_LENGTH, params: {length: 13}},
                            {name: ValidationRules.MAX_LENGTH, params: {length: 15}},
                        ],
                        phoneSelectOptions: residencyCountryPhonePrefixesList,
                        phonePlaceholder: 'inquiry.inquirySubject.formControls.placeholders.phone',
                        isLabelHidden: false,
                        label: 'inquiry.inquirySubject.formControls.labels.phone',
                        hostClass: 'col-xl-6 form-control required',
                    },
                }
            },
            {
                controlType: 'group',
                key: 'subject_submit',
                class: 'row button-container',
                controls: {
                    prevButton: {
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: 'button',
                        inputStyles: 'btn btn-theme btn-back',
                        defaultContainerStyles: '',
                        containerStyles: '',
                        defaultValue: null,
                        btnText: 'buttons.previous',
                        buttonDisabled: false,
                        customClickHandler: () => handlePrevClick(),
                    },
                    submitButton: {
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: 'submit',
                        inputStyles: 'btn-theme',
                        defaultContainerStyles: '',
                        containerStyles: '',
                        defaultValue: null,
                        btnText: 'buttons.next',
                        buttonDisabled: (mappedOutputValue: IInquirySubjectFormValue, isFormValid: boolean) => {
                            return !isFormValid;
                        }
                    },
                },
            },
        ]
    }
};
