import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'react-moment';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import './i18n';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import store, {persistor} from './store';
import {ConnectedRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import App from "./App";

Moment.startPooledTimer(1000);

const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
