import React, {useState} from 'react';
import {Checkbox, Loader, LoaderType, Translation} from "educat-common-web";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    changeCurrentStep,
    changeInquiryVariant, InquiryFormStep,
    ISelectedInquiryVariant
} from "../../../store/reducers/axaInsuranceSlice";
import {fetchInquiryVariants} from "../../../store/reducers/inquiryVariantListSlice";
import {
    inquiryVariantsSelector,
    isInquiryVariantsListLoadingSelector
} from "../../../store/selectors/inquiryVariantsListSelectors";
import VariantsTable from "./VariantsTable";
import {IInquiryVariant} from "../../../model/inquiryVariant";
import {inquiryVariantSelector} from "../../../store/selectors/axaInsuranceSelectors";
import MobileTilesHost from "./MobileTilesHost";
import VariantSummary, {PurchaseSummaryType} from "../shared/PurchaseSummary";
import PurchaseSummary from "../shared/PurchaseSummary";

const MOBILE_BREAKPOINT: number = 992;
interface IInquiryVariantStepProps {}

const DEFAULT_VARIANT_ID = '59644e11-5efc-4618-9be2-fa521edfe858';

const InquiryVariantStep: React.FC<IInquiryVariantStepProps> = () => {
    const dispatch = useDispatch(),
        isLoading = useSelector(isInquiryVariantsListLoadingSelector),
        [isMobile, setIsMobile] = useState<boolean | null>(null),
        inquiryVariants: IInquiryVariant[] | null = useSelector(inquiryVariantsSelector),
        selectedInquiryVariant: ISelectedInquiryVariant | null = useSelector(inquiryVariantSelector);

    const updateSize = () => {
        setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        if (inquiryVariants) return;
        dispatch(fetchInquiryVariants());
    }, []);

    useEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        if (inquiryVariants?.length && !selectedInquiryVariant) {
            dispatch(changeInquiryVariant({
                id: DEFAULT_VARIANT_ID,
                alcoholClause: false,
                physicalWork: false,
                isTermsAgreement: false,
            }));
        }
    }, [inquiryVariants]);


    const handleTermsAgreement = (checked: boolean) => {
        dispatch(changeInquiryVariant({
            id: selectedInquiryVariant?.id || DEFAULT_VARIANT_ID,
            alcoholClause: selectedInquiryVariant?.alcoholClause || false,
            physicalWork: selectedInquiryVariant?.physicalWork || false,
            isTermsAgreement: checked || false,
        }));
    }

    return (
        <div className="inquiry-form-card inquiry-variant">
            <h1 className="inquiry-step-title"><Translation text={'inquiry.inquiryVariant.description'}/></h1>

            {inquiryVariants && isMobile && <MobileTilesHost inquiryVariants={inquiryVariants}
                                                             selectedInquiryVariant={selectedInquiryVariant}/> }

            {inquiryVariants && <VariantsTable inquiryVariants={inquiryVariants}
                                                selectedInquiryVariant={selectedInquiryVariant}
                                                isMobile={isMobile}/> }

            <PurchaseSummary type={PurchaseSummaryType.Variant}
                             onPrev={() => dispatch(changeCurrentStep(InquiryFormStep.INQUIRY_SUBJECT))}
                             onNext={() => dispatch(changeCurrentStep(InquiryFormStep.INQUIRY_SUMMARY))} />

            <Loader type={LoaderType.Local} showLoader={isLoading}/>
        </div>
    )
}

export default InquiryVariantStep;
