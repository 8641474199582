import {educatAPI, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';

export function sendInquiryPropositionAPI(authToken: string | null, inquiryId: string, emailReturnUrl: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.put(
        `inquiries/${inquiryId}/send_proposition`,
        {
            emailReturnUrl: emailReturnUrl
        },
        new RestQueryParams(),
        headers
    );
}
